// App.js
import React, { useState, useEffect, useRef, memo } from 'react';
import { ChevronLeft, ChevronRight, Check, Plus, AlertCircle, X, Trash2, ChevronDown, Calendar, Loader2, Menu, Pencil, Power, InfoIcon, Settings, LogOut, Signal, Cloud, Server, Wifi, ArrowRight, Save, Mail, Thermometer, ShieldCheck, Minus, Trash, Droplets, Wind, ArrowUp, ArrowDown, Gauge, Zap, AlertTriangle, Clock, BellOff, Bell, RefreshCw, CheckCircle, Forward } from 'lucide-react';
import { Chart, registerables } from 'chart.js';
import 'chart.js/auto'; // Ensure Chart.js is properly registered
Chart.register(...registerables);

// Utility Functions
const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  const options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit' 
  };
  return new Date(dateString).toLocaleString('en-US', options);
};

const formatLastUpdated = (dateString) => {
  if (!dateString) return 'N/A';
  
  // Convert the UTC timestamp to local time
  const date = new Date(dateString);
  const now = new Date();
  
  // Calculate difference in milliseconds
  const diffMs = now - date;
  
  // Convert to minutes
  const diffMinutes = Math.floor(diffMs / 60000);
  
  // Format the output based on the time difference
  if (diffMinutes < 1) {
    return 'just now';
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffMinutes < 1440) { // less than 24 hours
    const hours = Math.floor(diffMinutes / 60);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diffMinutes / 1440);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
};

const METRICS = [
  {
    id: 'temperature',
    name: 'Temperature',
    icon: Thermometer,
    color: '#ef4444',
    unit: '°C',
    defaultValue: 20,
    min: -20,
    max: 50,
    step: 0.5,
    formatValue: (value) => `${value}°C`
  },
  {
    id: 'humidity',
    name: 'Humidity',
    icon: Droplets,
    color: '#3b82f6',
    unit: '%',
    defaultValue: 50,
    min: 0,
    max: 100,
    step: 1,
    formatValue: (value) => `${value}%`
  },
  {
    id: 'air_quality',
    name: 'Air Quality',
    icon: Wind,
    color: '#22c55e',
    unit: 'IAQ',  // Changed from kΩ
    defaultValue: 50, // Changed to a typical good IAQ value
    min: 0,
    max: 400, // Changed to match IAQ scale
    step: 1,
    formatValue: (value) => `${value} IAQ`  // Changed from kΩ
  },
  {
    id: 'pressure',
    name: 'Pressure',
    icon: Gauge,
    color: '#f59e0b',
    unit: 'hPa',
    defaultValue: 1013,
    min: 900,
    max: 1100,
    step: 1,
    formatValue: (value) => `${value} hPa`
  },
  {
    id: 'power_status',
    name: 'Power Status',
    icon: Zap,
    color: '#8b5cf6',
    unit: '',
    defaultValue: 0,
    min: 0,
    max: 1,
    step: 1,
    formatValue: () => 'power outage'
  }
];

const AlertConditionIcon = ({ condition }) => {
  switch (condition) {
    case 'above':
      return <ArrowUp className="w-6 h-6" />;
    case 'below':
      return <ArrowDown className="w-6 h-6" />;
    default:
      return null;
  }
};

const MetricCard = ({ metric, selected, onClick }) => (
  <button
    onClick={() => onClick(metric)}
    className={`
      p-6 rounded-lg transition-all duration-200 transform
      ${selected ? 'bg-accent-500 scale-105' : 'bg-secondary-700 hover:bg-secondary-600'}
      flex flex-col items-center gap-3
    `}
  >
    <metric.icon size={32} color={selected ? '#fff' : metric.color} />
    <span className="font-semibold">{metric.name}</span>
  </button>
);

const formatDuration = (duration, unit) => {
  if (duration === 0) return 'ever';
  return unit === 'hours' 
    ? `${duration} ${duration === 1 ? 'hour' : 'hours'}`
    : `${duration} ${duration === 1 ? 'minute' : 'minutes'}`;
};

const getAlertPreview = (metric, condition, value, duration, durationUnit) => {
  if (metric.id === 'power_status') {
    return `We'll send you an alert if there's a power outage${
      duration === 0 ? '.' : ` lasting more than ${formatDuration(duration, durationUnit)}.`
    }`;
  }

  return `We'll send you an alert if the ${metric.name.toLowerCase()} ${
    condition === 'above' ? 'rises above' : 'falls below'
  } ${metric.formatValue(value)}${
    duration === 0 ? '.' : ` for more than ${formatDuration(duration, durationUnit)}.`
  }`;
};


// Structural Components

const Badge = ({ children }) => (
  <span className="bg-accent-500 text-white text-xs ml-3 font-semibold mr-2 px-2.5 py-0.5 rounded">
    {children}
  </span>
);

const Input = ({ className = '', ...props }) => (
  <input
    className={`bg-primary-700 text-black rounded px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-accent-500 ${className}`}
    {...props}
  />
);

const Alert = ({ children, variant = 'default' }) => (
  <div className={`p-4 mb-4 rounded-md ${variant === 'destructive' ? 'bg-red-500' : 'bg-accent-500'}`}>
    {children}
  </div>
);

const DeviceActivation = ({ onActivationComplete }) => {
  const [activationCode, setActivationCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [step, setStep] = useState('enter-code');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleActivationCodeChange = (e) => {
    // Only allow alphanumeric characters, convert to uppercase
    const value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    setActivationCode(value.slice(0, 8)); // Limit to 8 characters
  };

  const handleActivation = async () => {
    if (activationCode.length !== 8) {
      setError('Activation code must be 8 characters long.');
      return;
    }
    
    setError('');
    setStep('activating');
    setIsSubmitting(true);

    try {
      const response = await fetch('https://cabinpulse.com/api/devices/verify', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ activation_code: activationCode })
      });

      if (!response.ok) {
        throw new Error('Invalid activation code');
      }

      setStep('name-device');
    } catch (error) {
      setError(error.message || 'Failed to verify activation code');
      setStep('enter-code');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameSubmit = async () => {
    if (!deviceName.trim()) {
      setError('Please enter a device name.');
      return;
    }

    setError('');
    setIsSubmitting(true);

    try {
      const response = await fetch('https://cabinpulse.com/api/devices/activate', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          activation_code: activationCode,
          name: deviceName
        })
      });

      if (!response.ok) {
        throw new Error('Failed to activate device');
      }

      setStep('success');
      setTimeout(() => {
        onActivationComplete();
      }, 2000);
    } catch (error) {
      setError(error.message || 'Failed to activate device');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-primary-900">
      <div className="bg-secondary-800 rounded-lg shadow-lg p-8 w-full max-w-md">
        <div className="flex flex-col items-center mb-6">
          <img src="/cpicon.png" alt="CabinPulse Logo" className="w-16 h-16 mb-4" />
          <h2 className="text-3xl font-bold text-white mb-2">Activate Your Device</h2>
          {step === 'enter-code' && (
            <p className="text-gray-400 text-center">Enter your 8-digit activation code</p>
          )}
        </div>

        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 rounded-md p-4 mb-4 flex items-center">
            <span className="text-red-500">{error}</span>
          </div>
        )}

        {step === 'enter-code' && (
          <>
            <input
              type="text"
              value={activationCode}
              onChange={handleActivationCodeChange}
              placeholder="Enter 8-digit code"
              className="w-full bg-primary-700 text-white rounded px-4 py-3 text-center font-mono text-xl tracking-wider mb-4"
              maxLength={8}
            />
            <button
              onClick={handleActivation}
              disabled={activationCode.length !== 8}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <>
                  <span>Continue</span>
                  <ArrowRight className="w-5 h-5" />
                </>
              )}
            </button>
          </>
        )}

        {step === 'activating' && (
          <div className="flex flex-col items-center">
            <Loader2 className="w-16 h-16 text-accent-500 animate-spin mb-4" />
            <p className="text-gray-400">Verifying activation code...</p>
          </div>
        )}

        {step === 'name-device' && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Device Name
              </label>
              <input
                type="text"
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                placeholder="Emma Lake Cabin"
                className="w-full bg-primary-700 text-white rounded px-4 py-3"
              />
            </div>
            <button
              onClick={handleNameSubmit}
              disabled={isSubmitting || !deviceName.trim()}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                'Activate Device'
              )}
            </button>
          </>
        )}

        {step === 'success' && (
          <div className="flex flex-col items-center">
            <div className="bg-green-500 rounded-full p-3 mb-4">
              <Check className="w-8 h-8 text-white" />
            </div>
            <p className="text-gray-400">Device activated successfully!</p>
          </div>
        )}
      </div>
    </div>
  );
};


const Button = ({ children, onClick, className = '', icon: Icon, type = 'button', disabled }) => (
  <button
    type={type}
    className={`bg-accent-500 hover:bg-accent-600 text-white font-bold py-2 px-4 rounded flex items-center ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={onClick}
    disabled={disabled}
  >
    {Icon && <Icon className="mr-2" size={16} />}
    {children}
  </button>
);

const Loader = () => (
  <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-accent-500"></div>
  </div>
);

const UpdateStatus = ({ 
  lastUpdated, 
  onRefresh,
  onUpdateRequired 
}) => {
  const [countdown, setCountdown] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const nextUpdateTimeRef = useRef(null);
  
  const handleRefreshSequence = async () => {
    setIsRefreshing(true);
    setCountdown('Checking for new data...');
    
    // Wait 10 seconds before doing the actual refresh
    await new Promise(resolve => setTimeout(resolve, 10000));
    
    try {
      await onRefresh();
      // Removed the second refresh call to avoid double loading and inconsistency
    } finally {
      setIsRefreshing(false);
    }
  };
  

  useEffect(() => {
    const formatCountdown = (milliseconds) => {
      if (milliseconds < 0) return null;
      const minutes = Math.floor(milliseconds / 60000);
      const seconds = Math.floor((milliseconds % 60000) / 1000);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const calculateStatus = () => {
      if (!lastUpdated) return;
      
      const now = new Date();
      const lastUpdate = new Date(lastUpdated);
      const FIFTEEN_MINUTES = 15 * 60 * 1000;
      
      // Update the next update time reference if it's not set or if we have a new lastUpdated timestamp
      if (!nextUpdateTimeRef.current || lastUpdate > new Date(nextUpdateTimeRef.current - FIFTEEN_MINUTES)) {
        nextUpdateTimeRef.current = new Date(lastUpdate.getTime() + FIFTEEN_MINUTES);
      }
      
      const timeSinceUpdate = now - lastUpdate;
      const timeUntilNextUpdate = nextUpdateTimeRef.current - now;
      
      if (!isRefreshing) {
        if (timeSinceUpdate >= FIFTEEN_MINUTES) {
          // If we're more than 15 minutes past the last update
          const minutesAgo = Math.floor(timeSinceUpdate / 60000);
          setCountdown(`Data last received ${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`);
          handleRefreshSequence();
        } else if (timeUntilNextUpdate <= 0) {
          // If we've reached the next update time
          handleRefreshSequence();
        } else {
          // Still counting down
          setCountdown(`Next update in ${formatCountdown(timeUntilNextUpdate)}`);
        }
      }
    };

    const interval = setInterval(calculateStatus, 1000);
    return () => clearInterval(interval);
  }, [lastUpdated, onRefresh, onUpdateRequired, isRefreshing]);

  const handleManualRefresh = () => {
    if (isRefreshing) return;
    handleRefreshSequence();
  };

  return (
    <div className="flex items-center gap-2">
      <Badge variant={countdown === 'Checking for new data...' ? 'info' : countdown.includes('ago') ? 'warning' : 'default'}>
        {countdown}
      </Badge>
      <button
        onClick={handleManualRefresh}
        disabled={isRefreshing}
        className={`p-2 rounded-full transition-all ${isRefreshing ? 'bg-secondary-700 cursor-not-allowed' : 'bg-secondary-800 hover:bg-secondary-700'}`}
        title="Refresh data"
      >
        <RefreshCw 
          size={16} 
          className={`text-white ${isRefreshing ? 'animate-spin' : 'hover:rotate-180 transition-transform duration-500'}`} 
        />
      </button>
    </div>
  );
};

const Header = ({ 
  onSettingsClick, 
  onAlertsClick, 
  selectedDevice, 
  onDeviceChange,
  lastUpdated,
  onLogout,
  devices,
  onRefresh,
  onUpdateRequired
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="mb-8">
      {/* Desktop Layout */}
      <div className="hidden md:flex items-center justify-between mb-6">
        <div className="flex items-center">
          <img src="/cpicon.png" alt="CabinPulse Logo" className="w-12 h-12 mr-3" />
          <div className="flex items-center justify-between text-2xl">
            <select 
              className="bg-primary-900 text-white rounded p-2"
              value={selectedDevice}
              onChange={(e) => onDeviceChange(e.target.value)}
            >
              {devices.map(device => (
                <option key={device.device_id} value={device.device_id}>
                  {device.name}
                </option>
              ))}
            </select>
          </div>
          <UpdateStatus 
            lastUpdated={lastUpdated} 
            onRefresh={onRefresh} 
            onUpdateRequired={onUpdateRequired}
          />
        </div>
        <nav className="flex space-x-4">
          <Button onClick={onSettingsClick}>
            <Settings size={16} className="mr-2" />
            Settings
          </Button>
          <Button onClick={onAlertsClick}>
            <Bell size={16} className="mr-2" />
            Alerts
          </Button>
          <Button onClick={onLogout} className="bg-red-500 hover:bg-red-600">
            <LogOut size={16} className="mr-2" />
            Logout
          </Button>
        </nav>
      </div>

      {/* Mobile Layout */}
      <div className="md:hidden">
        <div className="flex flex-col items-center relative mb-4">
          {/* Menu Button - Positioned Absolutely */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="absolute right-0 top-0 p-2 rounded-lg bg-secondary-800 hover:bg-secondary-700 transition-colors"
          >
            {isMobileMenuOpen ? (
              <X size={24} className="text-white" />
            ) : (
              <Menu size={24} className="text-white" />
            )}
          </button>

          {/* Logo and Device Selection - Centered */}
          <img src="/cpicon.png" alt="CabinPulse Logo" className="w-10 h-10 mb-3" />
          <div className="flex flex-col items-center w-full">
            <select 
              className="bg-primary-900 text-white rounded p-2 text-lg mb-3 max-w-[200px] text-center"
              value={selectedDevice}
              onChange={(e) => onDeviceChange(e.target.value)}
            >
              {devices.map(device => (
                <option key={device.device_id} value={device.device_id}>
                  {device.name}
                </option>
              ))}
            </select>
            <div className="flex justify-center w-full">
              <UpdateStatus 
                lastUpdated={lastUpdated} 
                onRefresh={onRefresh} 
                onUpdateRequired={onUpdateRequired}
              />
            </div>
          </div>
        </div>

        {/* Mobile Menu - Animated Slide Down */}
        <div
          className={`
            transition-all duration-300 ease-in-out overflow-hidden
            ${isMobileMenuOpen 
              ? 'max-h-48 opacity-100' 
              : 'max-h-0 opacity-0 pointer-events-none'
            }
          `}
        >
          <nav className="bg-secondary-800 rounded-lg p-4 space-y-3">
            <Button 
              onClick={() => {
                onSettingsClick();
                setIsMobileMenuOpen(false);
              }}
              className="w-full justify-center"
            >
              <Settings size={16} className="mr-2" />
              Settings
            </Button>
            <Button 
              onClick={() => {
                onAlertsClick();
                setIsMobileMenuOpen(false);
              }}
              className="w-full justify-center"
            >
              <Bell size={16} className="mr-2" />
              Alerts
            </Button>
            <Button 
              onClick={() => {
                onLogout();
                setIsMobileMenuOpen(false);
              }}
              className="w-full justify-center bg-red-500 hover:bg-red-600"
            >
              <LogOut size={16} className="mr-2" />
              Logout
            </Button>
          </nav>
        </div>
      </div>
    </header>
  );
};

const StatCard = ({ title, value, icon, updateTime, isSuccess = false }) => (
  <div className="bg-secondary-800 rounded-lg p-6 text-center">
    <h3 className="text-sm mb-2 text-gray-400">{title}</h3>
    <div className={`text-2xl font-bold mb-1 ${isSuccess ? 'text-success-500' : ''}`}>
      <i className={`fas fa-${icon} mr-2`}></i>
      {value}
    </div>
    <div className="text-xs text-gray-500">{updateTime}</div>
  </div>
);

// Air quality ranges and utilities
// Replace the existing AIR_QUALITY_RANGES with this new version
const AIR_QUALITY_RANGES = [
  { min: 0, max: 49.99, label: 'Excellent', color: '#0ea5e9', description: 'Air quality is excellent' },   // sky-500
  { min: 50, max: 99.99, label: 'Good', color: '#38bdf8', description: 'Air quality is good' },           // sky-400
  { min: 100, max: 149.99, label: 'Lightly Polluted', color: '#7dd3fc', description: 'Air quality is slightly polluted' }, // sky-300
  { min: 150, max: 199.99, label: 'Moderately Polluted', color: '#bae6fd', description: 'Air quality is moderately polluted' }, // sky-200
  { min: 200, max: 249.99, label: 'Heavily Polluted', color: '#e0f2fe', description: 'Air quality is heavily polluted' },  // sky-100
  { min: 250, max: 349.99, label: 'Severely Polluted', color: '#f0f9ff', description: 'Air quality is severely polluted' }, // sky-50
  { min: 350, max: Infinity, label: 'Extremely Polluted', color: '#f8fafc', description: 'Air quality is extremely polluted' } // slate-50
];

export const getAirQualityInfo = (value) => {
  if (!value && value !== 0) return { label: 'Unknown', color: '#6b7280', description: 'No data available' };
  const range = AIR_QUALITY_RANGES.find(range => value >= range.min && value <= range.max);
  return range || { label: 'Unknown', color: '#6b7280', description: 'Invalid reading' };
};

export const formatAirQuality = (value) => {
  if (!value && value !== 0) return 'N/A';
  const info = getAirQualityInfo(value);
  return `${info.label} (${Math.round(value)} IAQ)`;
};

const QuickStats = ({ stats }) => {
  // Helper function to get color for air quality
  const getAirQualityIconColor = (value) => {
    if (!value && value !== 0) return '#6b7280'; // gray-500 for unknown
    const info = getAirQualityInfo(value);
    return info.color;
  };

  // Helper function to get power status color
  const getPowerStatusColor = (status) => {
    return status ? '#22c55e' : '#ef4444'; // green-500 for on, red-500 for off
  };

  const [, forceUpdate] = useState({});

  useEffect(() => {
    // Calculate ms until next minute
    const now = new Date();
    const nextMinute = new Date(now.setMinutes(now.getMinutes() + 1, 0, 0));
    const timeUntilNextMinute = nextMinute - now;

    // Initial timeout to sync with minute mark
    const initialTimeout = setTimeout(() => {
      forceUpdate({});
      // Then set interval aligned with minutes
      const interval = setInterval(() => forceUpdate({}), 60000);
      return () => clearInterval(interval);
    }, timeUntilNextMinute);

    return () => clearTimeout(initialTimeout);
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
      {/* Temperature Card */}
      <div className="bg-secondary-800 rounded-lg p-6">
        <div className="flex items-center mb-2">
          <div className="p-2 rounded-lg bg-red-500 bg-opacity-20">
            <Thermometer 
              className="w-6 h-6"
              color="#ef4444" // red-500
            />
          </div>
          <h3 className="text-sm ml-3 text-gray-400">Temperature</h3>
        </div>
        <div className="text-4xl font-bold mb-1">
          {stats.temperature ? `${stats.temperature.toFixed(1)}°C` : 'N/A'}
        </div>
        <div className="text-xs text-gray-500">
          Updated {formatLastUpdated(stats.timestamp)}
        </div>
      </div>

      {/* Humidity Card */}
      <div className="bg-secondary-800 rounded-lg p-6">
        <div className="flex items-center mb-2">
          <div className="p-2 rounded-lg bg-blue-500 bg-opacity-20">
            <Droplets 
              className="w-6 h-6"
              color="#3b82f6" // blue-500
            />
          </div>
          <h3 className="text-sm ml-3 text-gray-400">Humidity</h3>
        </div>
        <div className="text-4xl font-bold mb-1">
          {stats.humidity ? `${stats.humidity.toFixed(1)}%` : 'N/A'}
        </div>
        <div className="text-xs text-gray-500">
          Updated {formatLastUpdated(stats.timestamp)}
        </div>
      </div>

      {/* Pressure Card */}
      <div className="bg-secondary-800 rounded-lg p-6">
        <div className="flex items-center mb-2">
          <div className="p-2 rounded-lg bg-amber-500 bg-opacity-20">
            <Gauge 
              className="w-6 h-6"
              color="#f59e0b" // amber-500
            />
          </div>
          <h3 className="text-sm ml-3 text-gray-400">Pressure</h3>
        </div>
        <div className="text-4xl font-bold mb-1">
          {stats.pressure ? `${stats.pressure.toFixed(1)} hPa` : 'N/A'}
        </div>
        <div className="text-xs text-gray-500">
          Updated {formatLastUpdated(stats.timestamp)}
        </div>
      </div>

      {/* Air Quality Card */}
      <div className="bg-secondary-800 rounded-lg p-6">
        <div className="flex items-center mb-2">
          <div className="p-2 rounded-lg bg-emerald-500 bg-opacity-20">
            <Wind 
              className="w-6 h-6"
              color="#10b981" // emerald-500
            />
          </div>
          <h3 className="text-sm ml-3 text-gray-400">Air Quality</h3>
        </div>
        <div className="text-4xl font-bold mb-1">
          {stats.air_quality !== undefined ? formatAirQuality(stats.air_quality) : 'N/A'}
        </div>
        {stats.air_quality !== undefined && (
          <div className="flex items-center mt-1 text-sm">
            <span 
              className="w-2 h-2 rounded-full mr-2" 
              style={{ backgroundColor: getAirQualityInfo(stats.air_quality).color }}
            />
            <span className="text-gray-400">
              {getAirQualityInfo(stats.air_quality).description}
            </span>
          </div>
        )}
        <div className="text-xs text-gray-500">
          Updated {formatLastUpdated(stats.timestamp)}
        </div>
      </div>

      {/* Power Status Card */}
      <div className="bg-secondary-800 rounded-lg p-6">
        <div className="flex items-center mb-2">
          <div className={`p-2 rounded-lg ${stats.power_status ? 'bg-green-500' : 'bg-red-500'} bg-opacity-20`}>
            <Zap 
              className="w-6 h-6"
              color={getPowerStatusColor(stats.power_status)}
            />
          </div>
          <h3 className="text-sm ml-3 text-gray-400">Power Status</h3>
        </div>
        <div className={`text-4xl font-bold mb-1 ${stats.power_status ? 'text-green-500' : 'text-red-500'}`}>
          {stats.power_status ? 'ON' : 'OUTAGE'}
        </div>
        <div className="text-xs text-gray-500">
          Updated {formatLastUpdated(stats.timestamp)}
        </div>
      </div>
    </div>
  );
};




const AlertsModal = ({ onClose, onUpdateAlerts, selectedDevice }) => {
  const [step, setStep] = useState('list');
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [newAlert, setNewAlert] = useState({
    condition: 'above',
    value: 0,
    duration: 5,
    durationUnit: 'minutes'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(`https://cabinpulse.com/api/alerts?device_id=${selectedDevice}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch alerts');
        }
        
        const data = await response.json();
        setAlerts(data.alerts);
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setError('Failed to load alerts');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchAlerts();
  }, [selectedDevice]);


  const handleMetricSelect = (metric) => {
    setSelectedMetric(metric);
    if (metric.id === 'power_status') {
      setNewAlert({
        ...newAlert,
        condition: 'below',
        value: 1,
      });
    } else {
      setNewAlert({
        ...newAlert,
        value: metric.defaultValue
      });
    }
  };


  const handleDeleteAlert = async (alert) => {
    try {
      const response = await fetch(`https://cabinpulse.com/api/alerts/${alert._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ device_id: selectedDevice })
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete alert');
      }
      
      const updatedAlerts = alerts.filter(a => a._id !== alert._id);
      setAlerts(updatedAlerts);
      onUpdateAlerts(updatedAlerts);
    } catch (error) {
      console.error('Error deleting alert:', error);
      setError('Failed to delete alert');
    }
  };


  const handleToggleAlert = async (alert) => {
    try {
      const response = await fetch(`https://cabinpulse.com/api/alerts/${alert._id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          enabled: !alert.enabled,
          device_id: selectedDevice
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to update alert');
      }
      
      const updatedAlerts = alerts.map(a => 
        a._id === alert._id ? { ...a, enabled: !a.enabled } : a
      );
      setAlerts(updatedAlerts);
      onUpdateAlerts(updatedAlerts);
    } catch (error) {
      console.error('Error updating alert:', error);
      setError('Failed to update alert');
    }
  };

  const handleCreateAlert = async () => {
    setIsSubmitting(true);
    setError('');
    
    try {
      const alert = {
        type: selectedMetric.id,
        condition: newAlert.condition,
        value: parseFloat(newAlert.value),
        duration: newAlert.duration === 0 ? 0 : 
          newAlert.durationUnit === 'hours' ? newAlert.duration * 60 : newAlert.duration,
        enabled: true,
        device_id: selectedDevice // Add device ID 
      };
      
      const response = await fetch('https://cabinpulse.com/api/alerts', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(alert)
      });
      
      if (!response.ok) {
        throw new Error('Failed to create alert');
      }
      
      const data = await response.json();
      const newAlerts = [...alerts, data.alert];
      setAlerts(newAlerts);
      onUpdateAlerts(newAlerts);
      setStep('list');
      setSelectedMetric(null);
      setNewAlert({
        condition: 'above',
        value: 0,
        duration: 5,
        durationUnit: 'minutes'
      });
    } catch (error) {
      console.error('Error creating alert:', error);
      setError('Failed to create alert');
    } finally {
      setIsSubmitting(false);
    }
  };

  

  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <div className="bg-secondary-700 rounded-full p-4 mb-6">
        <Clock size={32} className="text-accent-500" />
      </div>
      <h3 className="text-xl font-semibold mb-2">No Alerts Yet</h3>
      <p className="text-gray-400 text-center mb-8 max-w-sm">
        Set up alerts to get notified when your cabin's conditions change. Monitor temperature, humidity, power, and more.
      </p>
      <button
        onClick={() => setStep('select-metric')}
        className="bg-accent-500 hover:bg-accent-600 text-white font-semibold py-3 px-6 rounded-lg flex items-center gap-2 transition-colors"
      >
        <Plus size={20} />
        <span>Create Your First Alert</span>
      </button>
    </div>
  );

  const renderExistingAlerts = () => (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-500 bg-opacity-10 border border-red-500 rounded-md p-4 mb-4 flex items-center">
          <AlertCircle size={20} className="text-red-500 mr-2" />
          <span className="text-red-500">{error}</span>
        </div>
      )}
      
      {alerts.map((alert) => {
        const metric = METRICS.find(m => m.id === alert.type);
        const duration = alert.duration;
        const durationUnit = duration >= 60 ? 'hours' : 'minutes';
        const formattedDuration = duration >= 60 
          ? Math.floor(duration / 60)
          : duration;

        return (
          <div
            key={alert._id}
            className={`rounded-lg p-4 space-y-3 transition-all duration-200 ${
              alert.enabled 
                ? 'bg-secondary-700 border-l-4 border-green-500' 
                : 'bg-secondary-800 opacity-75'
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className={`p-2 rounded-full ${
                  alert.enabled ? 'bg-accent-500 bg-opacity-20' : 'bg-secondary-700'
                }`}>
                  <metric.icon 
                    size={24} 
                    color={alert.enabled ? metric.color : '#6B7280'} 
                  />
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="font-semibold">{metric.name}</h3>
                    <span className={`text-xs px-2 py-0.5 rounded-full ${
                      alert.enabled 
                        ? 'bg-accent-500 text-white' 
                        : 'bg-gray-600 text-gray-300'
                    }`}>
                      {alert.enabled ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                  <p className="text-sm text-gray-400">
                    {getAlertPreview(
                      metric,
                      alert.condition,
                      alert.value,
                      formattedDuration,
                      durationUnit
                    )}
                  </p>
                </div>
              </div>
              
              <div className="flex items-center gap-3">
                <button 
                  onClick={() => handleToggleAlert(alert)}
                  className={`p-2 rounded-full transition-colors ${
                    alert.enabled 
                      ? 'bg-green-500 hover:bg-accent-600' 
                      : 'bg-secondary-600 hover:bg-secondary-500'
                  }`}
                  title={alert.enabled ? 'Disable Alert' : 'Enable Alert'}
                >
                  {alert.enabled ? (
                    <Bell size={16} className="text-white" />
                  ) : (
                    <BellOff size={16} className="text-gray-400" />
                  )}
                </button>
                <button 
                  onClick={() => handleDeleteAlert(alert)}
                  className="p-2 rounded-full bg-secondary-600 hover:bg-red-500 hover:bg-opacity-20 transition-colors group"
                  title="Delete Alert"
                >
                  <Trash2 
                    size={16} 
                    className="text-gray-400 group-hover:text-red-500" 
                  />
                </button>
              </div>
            </div>
          </div>
        );
      })}
      
      <button
        onClick={() => setStep('select-metric')}
        className="w-full bg-secondary-700 hover:bg-secondary-600 text-white py-3 rounded-lg flex items-center justify-center gap-2 transition-colors mt-6"
      >
        <Plus size={20} />
        <span>Create New Alert</span>
      </button>
    </div>
  );

  const renderMetricSelection = () => (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      {METRICS.map(metric => (
        <MetricCard
          key={metric.id}
          metric={metric}
          selected={selectedMetric?.id === metric.id}
          onClick={handleMetricSelect}
        />
      ))}
    </div>
  );

  const renderPowerStatusConfiguration = () => (
    <div className="space-y-6">
      <div className="flex items-center gap-4 mb-8">
        <button
          onClick={() => setStep('select-metric')}
          className="p-2 rounded-full bg-secondary-700 hover:bg-secondary-600 transition-colors"
        >
          <X size={20} />
        </button>
        <div className="flex items-center gap-3">
          <Zap size={24} color={selectedMetric.color} />
          <h2 className="text-xl font-semibold">Power Outage Alert</h2>
        </div>
      </div>

      <div className="bg-secondary-700 rounded-lg p-6">
        <div className="flex items-center gap-3 mb-4">
          <AlertTriangle size={24} className="text-yellow-500" />
          <p className="text-lg">Alert me when there's a power outage</p>
        </div>

        <div className="space-y-4 mt-6">
          <div className="space-y-2">
            <label className="text-sm text-gray-400">Minimum Outage Duration</label>
            {newAlert.duration === 0 ? (
              <button
                onClick={() => setNewAlert({ ...newAlert, duration: 5 })}
                className="w-full bg-secondary-600 hover:bg-secondary-500 text-white py-2 px-4 rounded-lg"
              >
                <i className='fas fa-bell mr-2' />
                You'll be notified for any duration power outage
              </button>
            ) : (
              <div className="space-y-4">
                <div className="flex gap-4">
                  <input
                    type="number"
                    min="1"
                    max={newAlert.durationUnit === 'hours' ? 24 : 59}
                    value={newAlert.duration}
                    onChange={(e) => setNewAlert({ 
                      ...newAlert, 
                      duration: parseInt(e.target.value) || 0 
                    })}
                    className="bg-secondary-600 text-black rounded-lg px-4 py-2 w-24"
                  />
                  <select
                    value={newAlert.durationUnit}
                    onChange={(e) => setNewAlert({ 
                      ...newAlert, 
                      durationUnit: e.target.value,
                      duration: e.target.value === 'hours' ? 1 : 5
                    })}
                    className="bg-secondary-600 text-black rounded-lg px-4 py-2"
                  >
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                  </select>
                </div>
                <button
                  onClick={() => setNewAlert({ ...newAlert, duration: 0 })}
                  className="text-sm text-white hover:text-accent-400"
                >
                  Alert me for any duration instead
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-secondary-700 rounded-lg p-4 mt-4">
        <p className="text-gray-400">
          {getAlertPreview(
            selectedMetric,
            newAlert.condition,
            newAlert.value,
            newAlert.duration,
            newAlert.durationUnit
          )}
        </p>
      </div>

      <button
        onClick={handleCreateAlert}
        disabled={isSubmitting}
        className="w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg mt-6 flex items-center justify-center gap-2"
      >
        {isSubmitting ? (
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
        ) : (
          <>
            <Save size={20} />
            <span>Create Alert</span>
          </>
        )}
      </button>
    </div>
  );

  

  const renderStandardConfiguration = () => (
    <div className="space-y-6">
      <div className="flex items-center gap-4 mb-8">
        <button
          onClick={() => setStep('select-metric')}
          className="p-2 rounded-full bg-secondary-700 hover:bg-secondary-600 transition-colors"
        >
          <ChevronLeft size={20} />
        </button>
        <div className="flex items-center gap-3">
          <selectedMetric.icon size={24} color={selectedMetric.color} />
          <h2 className="text-xl font-semibold">{selectedMetric.name} Alert</h2>
        </div>
      </div>

      <div className="bg-secondary-700 rounded-lg p-4">
        <p className="text-gray-400">
          {getAlertPreview(
            selectedMetric,
            newAlert.condition,
            newAlert.value,
            newAlert.duration,
            newAlert.durationUnit
          )}
        </p>
      </div>

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <button
            className={`p-4 rounded-lg ${
              newAlert.condition === 'above' 
                ? 'bg-accent-500' 
                : 'bg-secondary-700 hover:bg-secondary-600'
            } flex flex-col items-center gap-2`}
            onClick={() => setNewAlert({ ...newAlert, condition: 'above' })}
          >
            <ArrowUp size={24} />
            <span>Above</span>
          </button>
          <button
            className={`p-4 rounded-lg ${
              newAlert.condition === 'below' 
                ? 'bg-accent-500' 
                : 'bg-secondary-700 hover:bg-secondary-600'
            } flex flex-col items-center gap-2`}
            onClick={() => setNewAlert({ ...newAlert, condition: 'below' })}
          >
            <ArrowDown size={24} />
            <span>Below</span>
          </button>
        </div>

        <div className="space-y-2">
          <label className="text-sm text-gray-400">
            Threshold Value ({selectedMetric.unit})
          </label>
          <input
            type="range"
            min={selectedMetric.min}
            max={selectedMetric.max}
            step={selectedMetric.step}
            value={newAlert.value}
            onChange={(e) => setNewAlert({ ...newAlert, value: e.target.value })}
            className="w-full h-2 bg-secondary-600 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between text-sm">
            <span>{selectedMetric.formatValue(selectedMetric.min)}</span>
            <span className="text-accent-500 font-semibold" style={{fontSize: '3rem',
    marginTop: '1rem', color: 'white'}}>
            {selectedMetric.formatValue(newAlert.value)}
            </span>
            <span>{selectedMetric.formatValue(selectedMetric.max)}</span>
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm text-gray-400">Duration</label>
          {newAlert.duration === 0 ? (
            <div className="space-y-2">
              <button
                onClick={() => setNewAlert({ ...newAlert, duration: 5 })}
                className="w-full bg-accent-500 text-white py-2 px-4 rounded-lg"
              >
                <i className='fas fa-bell mr-2'
                /> You'll be notified as soon as the condition is met
              </button>
              <p className="text-sm text-gray-400">
                You'll receive an alert as soon as the condition is met
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              <div>
              <div className="flex gap-4 items-center">
                <input
                  type="number"
                  min="1"
                  max={newAlert.durationUnit === 'hours' ? 24 : 59}
                  value={newAlert.duration}
                  onChange={(e) => setNewAlert({ 
                    ...newAlert, 
                    duration: parseInt(e.target.value) || 0 
                  })}
                  className="bg-secondary-600 text-black rounded-lg px-4 py-2 w-24"
                />
                <select
                  value={newAlert.durationUnit}
                  onChange={(e) => setNewAlert({ 
                    ...newAlert, 
                    durationUnit: e.target.value,
                    duration: e.target.value === 'hours' ? 1 : 5
                  })}
                  className="bg-secondary-600 text-black rounded-lg px-4 py-2"
                >
                  <option value="minutes">Minutes</option>
                  <option value="hours">Hours</option>
                </select>
              </div>
              <button
                  onClick={() => setNewAlert({ ...newAlert, duration: 0 })}
                  className="text-white mt-3 hover:text-accent-400 text-sm"
                >
                  Alert immediately instead
                </button>
              </div>
              <p className="text-sm text-gray-400">
                You'll receive an alert only if the condition persists for this duration
              </p>
            </div>
          )}
        </div>
      </div>

      <button
        onClick={handleCreateAlert}
        disabled={isSubmitting}
        className="w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg mt-6 flex items-center justify-center gap-2"
      >
        {isSubmitting ? (
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
        ) : (
          <>
            <Save size={20} />
            <span>Create Alert</span>
          </>
        )}
      </button>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-secondary-800 rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold">Alerts</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-secondary-700 rounded-full transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {step === 'list' && (
          isLoading ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-accent-500"></div>
            </div>
          ) : alerts.length === 0 ? (
            renderEmptyState()
          ) : (
            renderExistingAlerts()
          )
        )}

        {step === 'select-metric' && (
          <>
            <h3 className="text-lg font-semibold mb-4">Select Metric to Monitor</h3>
            {renderMetricSelection()}
            {selectedMetric && (
              <button
                onClick={() => setStep('configure')}
                className="mt-6 w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg flex items-center justify-center gap-2"
              >
                <i className="fas fa-cog mr-2"></i>
                <span>Configure Alert</span>
              </button>
            )}
          </>
        )}

        {step === 'configure' && selectedMetric && (
          selectedMetric.id === 'power_status' 
            ? renderPowerStatusConfiguration()
            : renderStandardConfiguration()
        )}
      </div>
    </div>
  );
};

const SettingsModal = ({ onClose, selectedDevice, onDeviceAdded, onDeviceUpdated }) => {
  const [userSettings, setUserSettings] = useState({
    name: '',
    email: '',
    phone: '',
    units: 'metric'
  });
  
  const [activeTab, setActiveTab] = useState("user");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [devices, setDevices] = useState([]);
  const [deviceStep, setDeviceStep] = useState('list');
  const [editingDevice, setEditingDevice] = useState(null);
  const [isEditing, setIsEditing] = useState({});
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [resettingDeviceId, setResettingDeviceId] = useState(null);
  const [isResetting, setIsResetting] = useState(false);

  // Fetch user profile and devices on mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch('https://cabinpulse.com/api/users/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.ok) {
          const userData = await response.json();
          setUserSettings({
            name: userData.name || '',
            email: userData.email || '',
            phone: userData.phone || '',
            units: userData.settings?.units || 'metric'
          });
        }
      } catch (error) {
        setError('Failed to load user profile');
      }
    };

    const fetchDevices = async () => {
      try {
        const response = await fetch('https://cabinpulse.com/api/devices', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.ok) {
          const deviceData = await response.json();
          // Filter to only include activated devices
          const activatedDevices = deviceData.filter(device => device.status === 'activated');
          setDevices(activatedDevices);
        }
      } catch (error) {
        setError('Failed to load devices');
      }
    };

    fetchUserProfile();
    fetchDevices();
  }, []);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleReset = async (deviceId) => {
    setIsResetting(true);
    try {
      const response = await fetch('https://cabinpulse.com/api/data/reset-device', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Device-ID': deviceId,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error('Failed to reset device');
      }
    } catch (error) {
      console.error('Error resetting device:', error);
    } finally {
      setIsResetting(false);
      setShowResetDialog(false);
      setResettingDeviceId(null);
    }
  };

  const handleSaveUserSettings = async () => {
    setIsSubmitting(true);
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch('https://cabinpulse.com/api/users/profile', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: userSettings.name,
          phone: userSettings.phone,
          settings: {
            units: userSettings.units
          }
        })
      });

      if (response.ok) {
        setSuccessMessage('Settings saved successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to save settings');
      }
    } catch (error) {
      setError('An error occurred while saving settings');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRenameDevice = async (deviceId, newName) => {
    if (!newName.trim()) {
      setError('Device name cannot be empty');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch(`https://cabinpulse.com/api/devices/${deviceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: newName })
      });

      if (!response.ok) {
        throw new Error('Failed to rename device');
      }

      const updatedDevices = devices.map(device =>
        device.device_id === deviceId ? { ...device, name: newName } : device
      );
      setDevices(updatedDevices);
      if (onDeviceUpdated) onDeviceUpdated(updatedDevices);
      setIsEditing({});
      setSuccessMessage('Device renamed successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(err.message || 'Failed to rename device');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteDevice = async (deviceId) => {
    if (!window.confirm('Are you sure you want to delete this device?')) {
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch(`https://cabinpulse.com/api/devices/${deviceId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        setDevices(prev => prev.filter(device => device.device_id !== deviceId));
        setSuccessMessage('Device deleted successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        throw new Error('Failed to delete device');
      }
    } catch (error) {
      setError('Failed to delete device');
    } finally {
      setIsSubmitting(false);
    }
  };

  const AddDeviceForm = ({ onBack }) => {
    const [activationCode, setActivationCode] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleActivationCodeChange = (e) => {
      // Only allow alphanumeric characters, convert to uppercase
      const value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
      setActivationCode(value.slice(0, 8));
    };

    const handleActivate = async () => {
      if (activationCode.length !== 8) {
        setError('Activation code must be 8 characters');
        return;
      }

      if (!deviceName.trim()) {
        setError('Device name is required');
        return;
      }

      setIsSubmitting(true);
      setError('');

      try {
        const response = await fetch('https://cabinpulse.com/api/devices/activate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            activation_code: activationCode,
            name: deviceName
          })
        });

        if (!response.ok) {
          throw new Error('Failed to activate device');
        }

        const data = await response.json();
        if (data.device.status === 'activated') {
          setDevices(prev => [...prev, data.device]);
          if (onDeviceAdded) onDeviceAdded(data.device);
          setDeviceStep('list');
          setSuccessMessage('Device activated successfully');
          setTimeout(() => setSuccessMessage(''), 3000);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (err) {
        setError(err.message || 'Failed to activate device');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <div className="space-y-6">
        <div className="flex items-center mb-6">
          <button
            onClick={onBack}
            className="p-2 rounded-full bg-secondary-700 hover:bg-secondary-600 transition-colors mr-4"
          >
            <ChevronLeft size={20} />
          </button>
          <h3 className="text-xl font-semibold">Add New Device</h3>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Activation Code
            </label>
            <input
              type="text"
              value={activationCode}
              onChange={handleActivationCodeChange}
              className="w-full bg-primary-700 text-white rounded px-4 py-2 font-mono text-center tracking-wider"
              placeholder="XXXXXXXX"
              maxLength={8}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Device Name
            </label>
            <input
              type="text"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              className="w-full bg-primary-700 text-white rounded px-4 py-2"
              placeholder="e.g., Lake House"
            />
          </div>
        </div>

        <button
          onClick={handleActivate}
          disabled={isSubmitting || activationCode.length !== 8 || !deviceName.trim()}
          className="w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? (
            <Loader2 size={20} className="animate-spin" />
          ) : (
            'Activate Device'
          )}
        </button>
      </div>
    );
  };

  const renderDevicesTab = () => {
    if (deviceStep === 'add') {
      return (
        <AddDeviceForm 
          onBack={() => {
            setDeviceStep('list');
            setError('');
          }}
        />
      );
    }

    return (
      <div className="space-y-4">
        {devices.map(device => (
          <div
            key={device.device_id}
            className="bg-primary-900 rounded-lg p-4 space-y-3"
          >
            <div className="flex items-center justify-between">
              {isEditing[device.device_id] ? (
                <div className="flex-1 mr-4">
                  <input
                    type="text"
                    value={editingDevice || device.name}
                    onChange={(e) => setEditingDevice(e.target.value)}
                    className="w-full bg-secondary-600 text-black rounded px-3 py-2"
                    placeholder="Device name"
                  />
                </div>
              ) : (
                <div className="flex-1">
                  <span className="text-lg font-semibold">{device.name}</span>
                </div>
              )}
              
              <div className="flex items-center gap-2">
                {isEditing[device.device_id] ? (
                  <>
                    <button
                      onClick={() => handleRenameDevice(device.device_id, editingDevice)}
                      disabled={isSubmitting}
                      className="p-2 rounded-full bg-accent-500 hover:bg-accent-600 transition-colors"
                    >
                      {isSubmitting ? (
                        <Loader2 size={16} className="animate-spin" />
                      ) : (
                        <Check size={16} />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setIsEditing({});
                        setEditingDevice(null);
                      }}
                      className="p-2 rounded-full bg-secondary-600 hover:bg-secondary-500 transition-colors"
                    >
                      <X size={16} />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setIsEditing({ [device.device_id]: true });
                        setEditingDevice(device.name);
                      }}
                      className="p-2 rounded-full bg-secondary-600 hover:bg-secondary-500 transition-colors"
                    >
                      <Pencil size={16} />
                    </button>
                    <button
                      onClick={() => handleDeleteDevice(device.device_id)}
                      className="p-2 rounded-full bg-secondary-600 hover:bg-secondary-500 transition-colors text-red-500"
                    >
                      <Trash2 size={16} />
                    </button>
                  </>
                )}
              </div>
            </div>
            
            {/* Device Details */}
            <div className="text-sm">
              <div className="text-gray-400">
                Device ID: <span className="font-mono">{device.device_id}</span>
              </div>
              
              {/* Plan Details */}
              {device.plan && (
                <div className="mt-2 p-3 bg-secondary-800 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-white font-semibold">Subscription Plan</span>
                    <span className="bg-accent-500 text-white px-2 py-1 rounded text-xs">
                      {device.plan}
                    </span>
                  </div>
                  {device.plan_end_date && (
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-400">Expires</span>
                      <span className="text-white">
                        {new Date(device.plan_end_date).toLocaleDateString()}
                      </span>
                    </div>
                  )}
                  <div className="mt-2 space-y-1">
                    <div className="flex items-center text-gray-400">
                      <Check size={14} className="text-green-500 mr-2" />
                      24/7 Monitoring
                    </div>
                    <div className="flex items-center text-gray-400">
                      <Check size={14} className="text-green-500 mr-2" />
                      Real-time Alerts
                    </div>
                    <div className="flex items-center text-gray-400">
                      <Check size={14} className="text-green-500 mr-2" />
                      Historical Data
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-between items-center mt-4">
                <div className="flex items-center">
                  <CheckCircle size={16} className="text-green-500 mr-2" />
                  <span className="text-green-500">Activated</span>
                </div>
              </div>
            </div>
          </div>
        ))}
        
        <button
          onClick={() => setDeviceStep('add')}
          className="w-full bg-secondary-700 hover:bg-secondary-600 text-white py-3 rounded-lg flex items-center justify-center gap-2 transition-colors"
        >
          <Plus size={20} />
          <span>Add New Device</span>
        </button>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-secondary-800 rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-white">Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {/* Tabs */}
        <div className="flex space-x-1 mb-6">
          <button
            className={`px-4 py-2 rounded-t-lg font-semibold transition-colors ${
              activeTab === "user"
                ? "bg-accent-500 text-white"
                : "bg-secondary-700 text-gray-400 hover:text-white"
            }`}
            onClick={() => setActiveTab("user")}
          >
            User Settings
          </button>
          <button
            className={`px-4 py-2 rounded-t-lg font-semibold transition-colors ${
              activeTab === "devices"
                ? "bg-accent-500 text-white"
                : "bg-secondary-700 text-gray-400 hover:text-white"
            }`}
            onClick={() => setActiveTab("devices")}
          >
            Devices
          </button>
        </div>

        {/* Error/Success Messages */}
        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}
        {successMessage && (
          <div className="bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-4 py-3 rounded mb-4">
            {successMessage}
          </div>
        )}

        {/* Content */}
        {activeTab === "user" && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={userSettings.name}
                onChange={handleUserChange}
                className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={userSettings.email}
                disabled
                className="w-full bg-primary-700 text-white rounded px-4 py-2 cursor-not-allowed opacity-60"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Phone
              </label>
              <input
                type="tel"
                name="phone"
                value={userSettings.phone}
                onChange={handleUserChange}
                className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Units
              </label>
              <select
                name="units"
                value={userSettings.units}
                onChange={handleUserChange}
                className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
              >
                <option value="metric">Metric (°C)</option>
                <option value="imperial">Imperial (°F)</option>
              </select>
            </div>
          </div>
        )}

        {activeTab === "devices" && renderDevicesTab()}

        {/* Footer */}
        <div className="flex justify-end space-x-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded bg-gray-600 text-white hover:bg-gray-500 transition-colors"
          >
            Cancel
          </button>
          {activeTab === "user" && (
            <button
              onClick={handleSaveUserSettings}
              disabled={isSubmitting}
              className={`px-4 py-2 rounded bg-accent-500 text-white flex items-center space-x-2 transition-colors
                ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-600'}`}
            >
              <Save size={20} />
              <span>Save Changes</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};







const AlertItemComponent = ({ text, enabled, onToggle }) => (
  <div className="flex items-center justify-between py-2 border-b border-accent-500 last:border-b-0">
    <span className="mr-4">
      <i className="fas fa-bell mr-2"></i>
      {text}
    </span>
    <label className="relative inline-flex items-center cursor-pointer">
      <input 
        type="checkbox" 
        className="sr-only peer"
        checked={enabled}
        onChange={onToggle}
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>
  </div>
);

const NewAlertForm = ({ onCreateAlert }) => {
  const [alertType, setAlertType] = useState('');
  const [condition, setCondition] = useState('');
  const [value, setValue] = useState('');
  const [duration, setDuration] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (alertType && condition && value && duration) {
      onCreateAlert({
        type: alertType,
        condition,
        value: parseFloat(value),
        duration: parseInt(duration, 10) // Duration in minutes
      });
      // Reset form
      setAlertType('');
      setCondition('');
      setValue('');
      setDuration('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <select 
        value={alertType} 
        onChange={(e) => setAlertType(e.target.value)}
        className="w-full bg-accent-500 rounded p-2 mb-2"
        required
      >
          <option value="">Select alert type</option>
          <option value="temperature">Temperature</option>
          <option value="humidity">Humidity</option>
          <option value="pressure">Pressure</option>
          <option value="air_quality">Air Quality</option>
          <option value="power_status">Power Status</option>
      </select>
      <select 
        value={condition} 
        onChange={(e) => setCondition(e.target.value)}
        className="w-full bg-accent-500 rounded p-2 mb-2"
        required
      >
        <option value="">Select condition</option>
        <option value="above">Above</option>
        <option value="below">Below</option>
        <option value="equals">Equals</option>
      </select>
      <input 
        type="number" 
        value={value} 
        onChange={(e) => setValue(e.target.value)}
        placeholder="Value"
        className="w-full bg-accent-500 rounded p-2 mb-2"
        required
      />
      <input 
        type="number" 
        value={duration} 
        onChange={(e) => setDuration(e.target.value)}
        placeholder="Duration (minutes)"
        className="w-full bg-accent-500 rounded p-2 mb-2"
        min="1"
        required
      />
      <Button type="submit" disabled={false}>Create Alert</Button>
    </form>
  );
};



const ChartComponent = ({ 
  title, 
  icon, 
  data, 
  type = 'line', 
  metric = 'default', 
  isLoading, 
  timeRange, 
  unit = '',
  startDate,
  endDate 
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const generateTimePoints = (start, end, interval = 5) => {
    const points = [];
    const currentTime = new Date(start);
    const endTime = new Date(end);

    while (currentTime <= endTime) {
      points.push(new Date(currentTime));
      currentTime.setMinutes(currentTime.getMinutes() + interval);
    }

    return points;
  };

  useEffect(() => {
    if (isLoading || !chartRef.current || !startDate || !endDate) return;
  
    const ctx = chartRef.current.getContext('2d');
    if (!ctx) return;
  
    // Always destroy existing chart before creating a new one
    if (chartInstance.current) {
      chartInstance.current.destroy();
      chartInstance.current = null;
    }
  
    const formatLabel = (timestamp) => {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      switch(timeRange) {
        case 'hour':
          return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        case 'day':
          return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        case 'month':
          return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
        default:
          return date.toLocaleString();
      }
    };
  
    // Generate complete timeline
    const timePoints = generateTimePoints(startDate, endDate);
    
    // Create a map of existing data points for lookup
    const dataMap = new Map(
      data.map(d => [new Date(d.timestamp).getTime(), d.value])
    );
  
    // Configure tick spacing based on time range
    const getTickSpacing = () => {
      switch(timeRange) {
        case 'hour':
          return 6; // Show every 30 minutes (6 * 5min intervals)
        case 'day':
          return 6; // Show every 6 hours
        case 'month':
          return 4; // Show every 4 days
        default:
          return 6;
      }
    };

    if (metric === 'air_quality') {
      const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
      [...AIR_QUALITY_RANGES].reverse().forEach((range, index, array) => {
        const stopPercentage = index / (array.length - 1);
        gradientFill.addColorStop(stopPercentage, range.color + '40');
      });
    
      const processedData = timePoints.map(timestamp => {
        const value = dataMap.get(timestamp.getTime());
        return {
          x: timestamp,
          y: value ? 400 - value : null, // Invert for display
          originalValue: value // Keep original value for tooltips
        };
      });
    
      chartInstance.current = new Chart(ctx, {
        type,
        data: {
          labels: timePoints.map(t => formatLabel(t)),
          datasets: [{
            data: processedData,
            borderWidth: 2,
            tension: 0.1,
            borderColor: (context) => {
              const originalValue = context.raw?.originalValue;
              return originalValue ? getAirQualityInfo(originalValue).color : '#6b7280';
            },
            backgroundColor: gradientFill,
            fill: true,
            spanGaps: true
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              max: 400,
              grid: { color: 'rgba(255, 255, 255, 0.1)' },
              ticks: { 
                color: '#ffffff',
                callback: function(value) {
                  return `${Math.round(400 - value)} IAQ`;
                }
              },
              title: {
                display: true,
                text: 'Air Quality (IAQ)',
                color: '#ffffff',
                font: {
                  size: 12,
                  weight: 'bold'
                },
                padding: { top: 10, bottom: 10 }
              }
            },
            x: {
              grid: { color: 'rgba(255, 255, 255, 0.1)' },
              ticks: { 
                color: '#ffffff',
                maxRotation: 45,
                minRotation: 45,
                autoSkip: true,
                maxTicksLimit: 12,
                callback: function(value, index) {
                  const spacing = getTickSpacing();
                  return index % spacing === 0 ? this.getLabelForValue(value) : '';
                }
              }
            }
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const originalValue = context.raw?.originalValue;
                  if (originalValue === null || originalValue === undefined) return 'No Data';
                  const info = getAirQualityInfo(originalValue);
                  return `${info.label}: ${Math.round(originalValue)} IAQ`;
                }
              }
            }
          }
        }
      });
    } else if (metric === 'power_status') {
      let processedData;
      
      if (timeRange === 'month') {
        // Generate array of all days in the month
        const start = new Date(startDate);
        const end = new Date(endDate);
        const allDays = [];
        const currentDay = new Date(start);
        
        while (currentDay <= end) {
          allDays.push(new Date(currentDay));
          currentDay.setDate(currentDay.getDate() + 1);
        }
      
        // Create a map of the data we got from the API
        const dataMap = new Map(data.map(d => [
          new Date(d.timestamp).toISOString().split('T')[0],
          d.value
        ]));
      
        processedData = allDays.map(day => {
          const powerStatus = dataMap.get(day.toISOString().split('T')[0]) || {};
          return {
            timestamp: day,
            status: powerStatus.status || 'no-data',
            outageMinutes: powerStatus.total_outage_minutes || 0,
            totalDatapoints: powerStatus.total_datapoints * 5
          };
        });
      } else if (timeRange === 'day') {
        processedData = data.map(hourData => {
          const status = hourData.value === 0 ? 'outage' : 
                        hourData.value === 1 ? 'power' :
                        hourData.value === null ? 'no-data' : 'power';
          return {
            timestamp: hourData.timestamp,
            status: status
          };
        });
      } else {
        processedData = timePoints.map(timestamp => {
          const value = dataMap.get(timestamp.getTime());
          return {
            timestamp: timestamp.toISOString(),
            status: value === undefined || value === null ? 'no-data' : 
                   value === 0 ? 'outage' : 'power'
          };
        });
      }
  
      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: processedData.map(d => formatLabel(d.timestamp)),
          datasets: [{
            data: processedData.map(d => 1),
            backgroundColor: processedData.map(d => {
              if (timeRange === 'month') {
                switch (d.status) {
                  case 'no-data': return '#6B7280';
                  case 'full-outage': return '#DC2626';
                  case 'partial-outage': return '#FBBF24';
                  default: return '#22C55E';
                }
              } else {
                return d.status === 'no-data' ? '#6B7280' :
                       d.status === 'outage' ? '#DC2626' :
                       '#22C55E';
              }
            }),
            borderWidth: 0,
            barPercentage: 1,
            categoryPercentage: 1,
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: false,
              min: 0,
              max: 1
            },
            x: {
              grid: { display: false },
              ticks: { 
                color: '#ffffff',
                maxRotation: 45,
                minRotation: 45,
                autoSkip: true,
                maxTicksLimit: 12,
                callback: function(value, index) {
                  const spacing = getTickSpacing();
                  return index % spacing === 0 ? this.getLabelForValue(value) : '';
                }
              }
            }
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const dataPoint = processedData[context.dataIndex];
                  if (timeRange === 'month') {
                    switch (dataPoint.status) {
                      case 'no-data':
                        return 'No Data Available';
                      case 'full-outage':
                        return `Full Day Outage (${dataPoint.totalDatapoints} minutes)`;
                      case 'partial-outage':
                        const outagePercentage = Math.round(
                          (dataPoint.outageMinutes / 1440) * 100
                        );
                        return `Power Outages: ${dataPoint.outageMinutes} minutes (${outagePercentage}% of day)`;
                      default:
                        return 'No Outages';
                    }
                  } else {
                    return dataPoint.status === 'no-data' ? 'No Data Available' :
                           dataPoint.status === 'outage' ? 'Power Outage' : 'Power On';
                  }
                }
              }
            }
          }
        }
      });
    } else {
      // Process data for other metrics with continuous timeline
      const processedData = timePoints.map(timestamp => {
        const value = dataMap.get(timestamp.getTime());
        return {
          x: timestamp,
          y: value
        };
      });
  
      chartInstance.current = new Chart(ctx, {
        type,
        data: {
          labels: timePoints.map(t => formatLabel(t)),
          datasets: [{
            data: processedData,
            borderWidth: 2,
            tension: 0.1,
            borderColor: '#e94560',
            backgroundColor: type === 'bar' ? '#0f3460' : undefined,
            spanGaps: true
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              grid: { color: 'rgba(255, 255, 255, 0.1)' },
              ticks: { 
                color: '#ffffff',
                callback: function(value) {
                  return `${value}${unit}`;
                },
                maxTicksLimit: 8
              },
              title: {
                display: true,
                text: unit,
                color: '#ffffff',
                font: {
                  size: 12,
                  weight: 'bold'
                },
                padding: { top: 10, bottom: 10 }
              }
            },
            x: {
              grid: { color: 'rgba(255, 255, 255, 0.1)' },
              ticks: { 
                color: '#ffffff',
                maxRotation: 45,
                minRotation: 45,
                autoSkip: true,
                maxTicksLimit: 12,
                callback: function(value, index) {
                  const spacing = getTickSpacing();
                  return index % spacing === 0 ? this.getLabelForValue(value) : '';
                }
              }
            }
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const value = context.parsed.y;
                  return value === null ? 'No Data' : `${value}${unit}`;
                }
              }
            }
          }
        }
      });
    }
  
    // Return cleanup function
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [data, type, isLoading, timeRange, unit, metric, startDate, endDate]);
      

  return (
    <div className="bg-secondary-700 rounded-lg p-6">
      <div className="flex items-center mb-4">
        <i className={`fas fa-${icon} mr-2`}></i>
        <h2 className="text-xl font-semibold">{title}</h2>
      </div>
      {metric === 'power_status' && (
        <div className="flex justify-end space-x-4 mb-4 text-sm">
          <div className="flex items-center">
            <div className="w-3 h-3 bg-gray-500 rounded-full mr-2"></div>
            <span>No Data</span>
          </div>
          {timeRange === 'month' ? (
            <>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-red-600 rounded-full mr-2"></div>
                <span>Full Day Outage</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-yellow-400 rounded-full mr-2"></div>
                <span>Partial Outage</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                <span>No Outages</span>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-red-600 rounded-full mr-2"></div>
                <span>Outage</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                <span>Power On</span>
              </div>
            </>
          )}
        </div>
      )}
      <div className="h-64 relative">
        <canvas ref={chartRef}></canvas>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-secondary-700 bg-opacity-75">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-accent-500"></div>
          </div>
        )}
      </div>
    </div>
  );
};


// Insights Component
const Insights = ({ startDate, endDate, onDateChange, insights, isLoading }) => {
  const [isMobileDateMenuOpen, setIsMobileDateMenuOpen] = useState(false);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  // Update local dates when props change
  useEffect(() => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [startDate, endDate]);

  // Format date for display
  const formatDateForDisplay = (date) => {
    return new Date(date).toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Handle local date changes and update parent
  const handleDateChange = (type, value) => {
    if (type === 'start') {
      setLocalStartDate(value);
      onDateChange('start', value);
    } else {
      setLocalEndDate(value);
      onDateChange('end', value);
    }
  };

  // Handle mobile date changes
  const handleMobileDateChange = (type, value) => {
    if (type === 'start') {
      setLocalStartDate(value);
    } else {
      setLocalEndDate(value);
    }
  };

  // Apply mobile date changes
  const handleApplyDates = () => {
    onDateChange('start', localStartDate);
    onDateChange('end', localEndDate);
    setIsMobileDateMenuOpen(false);
  };

  return (
    <div className="bg-secondary-800 rounded-lg p-8 mb-8 shadow-xl">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center mb-8">
        {/* Title Section */}
        <div className="flex items-center">
          <i className="fas fa-lightbulb text-2xl text-accent-500 mr-3"></i>
          <h2 className="text-3xl font-bold">Insights</h2>
        </div>

        {/* Desktop Date Range Selector */}
        <div className="hidden md:flex items-center space-x-4">
          <div className="relative">
            <input
              type="date"
              value={startDate}
              onChange={(e) => handleDateChange('start', e.target.value)}
              className="bg-primary-900 text-white rounded-lg p-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-accent-500"
              max={endDate}
            />
          </div>
          <span className="text-accent-500">to</span>
          <div className="relative">
            <input
              type="date"
              value={endDate}
              onChange={(e) => handleDateChange('end', e.target.value)}
              className="bg-primary-900 text-white rounded-lg p-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-accent-500"
              min={startDate}
            />
          </div>
        </div>

        {/* Mobile Date Range Button */}
        <div className="md:hidden w-full">
          <button
            onClick={() => setIsMobileDateMenuOpen(!isMobileDateMenuOpen)}
            className="w-full bg-secondary-700 p-3 rounded-lg flex items-center justify-between"
          >
            <div className="flex items-center">
              <Calendar size={16} className="mr-2 text-accent-500" />
              <span className="text-sm">
                {formatDateForDisplay(startDate)} - {formatDateForDisplay(endDate)}
              </span>
            </div>
            <ChevronDown
              size={16}
              className={`transform transition-transform duration-200 ${
                isMobileDateMenuOpen ? 'rotate-180' : ''
              }`}
            />
          </button>

          {/* Mobile Date Range Selector Dropdown */}
          <div
            className={`
              mt-2 overflow-hidden transition-all duration-200 ease-in-out
              ${isMobileDateMenuOpen ? 'max-h-[300px] opacity-100' : 'max-h-0 opacity-0'}
            `}
          >
            <div className="bg-secondary-700 rounded-lg p-4 space-y-4">
              <div className="space-y-2">
                <label className="block text-sm text-gray-400">Start Date</label>
                <input
                  type="date"
                  value={localStartDate}
                  onChange={(e) => handleMobileDateChange('start', e.target.value)}
                  className="w-full bg-primary-900 text-white rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-accent-500 appearance-none"
                  max={localEndDate}
                  style={{
                    colorScheme: 'dark',
                    WebkitAppearance: 'none',
                    MozAppearance: 'none'
                  }}
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm text-gray-400">End Date</label>
                <input
                  type="date"
                  value={localEndDate}
                  onChange={(e) => handleMobileDateChange('end', e.target.value)}
                  className="w-full bg-primary-900 text-white rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-accent-500 appearance-none"
                  min={localStartDate}
                  style={{
                    colorScheme: 'dark',
                    WebkitAppearance: 'none',
                    MozAppearance: 'none'
                  }}
                />
              </div>
              <button
                onClick={handleApplyDates}
                className="w-full bg-accent-500 hover:bg-accent-600 text-white py-3 rounded-lg flex items-center justify-center transition-colors"
              >
                <Check size={16} className="mr-2" />
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      
      
      {isLoading ? (
        <Loader />
      ) : insights && insights.temperature ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <InsightCard
            title="Temperature"
            icon="temperature-high"
            highValue={insights.temperature.high}
            lowValue={insights.temperature.low}
            unit="°C"
          />
          <InsightCard
            title="Humidity"
            icon="tint"
            highValue={insights.humidity.high}
            lowValue={insights.humidity.low}
            unit="%"
          />
<InsightCard
  title="Air Quality"
  icon="wind"
  highValue={insights.air_quality?.high}
  lowValue={insights.air_quality?.low}
  unit=" IAQ"  // Changed from kΩ
/>
          <InsightCard
            title="Pressure"
            icon="tachometer-alt"
            highValue={insights.pressure.high}
            lowValue={insights.pressure.low}
            unit=" hPa"
          />
          <PowerOutageInsightCard
            title="Power Outages"
            icon="bolt"
            outages={insights.power_status?.outages || []}
            totalOutages={insights.power_status?.total_outages || 0}
            totalDuration={insights.power_status?.total_outage_duration || 0}
          />
        </div>
      ) : (
        <div className="text-center text-gray-400">
          <p>No insights available. Please try adjusting the date range.</p>
        </div>
      )}
    </div>
  );
};

// Update InsightCard component to better handle the data
const InsightCard = ({ title, icon, highValue, lowValue, unit }) => {
  if (title === "Air Quality") {
    return (
      <div className="bg-primary-900 rounded-lg p-6 flex flex-col h-full transition-all duration-300 hover:shadow-lg hover:scale-105">
        <div className="flex items-center mb-4">
          <i className={`fas fa-${icon} text-2xl text-accent-500 mr-3`}></i>
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
        {highValue && (
          <div className="mb-4">
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm text-gray-400">Highest</span>
              <div className="flex items-center">
                <span className="inline-block w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: getAirQualityInfo(highValue.value).color }} />
                <span className="text-2xl font-bold">
                  {highValue.value.toFixed(0)} IAQ
                </span>
              </div>
            </div>
            <div className="text-sm text-gray-400">
              {getAirQualityInfo(highValue.value).label}
            </div>
            <div className="text-xs text-gray-500">
              {formatDateTime(highValue.timestamp)}
            </div>
          </div>
        )}
        {lowValue && (
          <div className="mb-4">
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm text-gray-400">Lowest</span>
              <div className="flex items-center">
                <span className="inline-block w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: getAirQualityInfo(lowValue.value).color }} />
                <span className="text-2xl font-bold">
                  {lowValue.value.toFixed(0)} IAQ
                </span>
              </div>
            </div>
            <div className="text-sm text-gray-400">
              {getAirQualityInfo(lowValue.value).label}
            </div>
            <div className="text-xs text-gray-500">
              {formatDateTime(lowValue.timestamp)}
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="bg-primary-900 rounded-lg p-6 flex flex-col h-full transition-all duration-300 hover:shadow-lg hover:scale-105">
      <div className="flex items-center mb-4">
        <i className={`fas fa-${icon} text-2xl text-accent-500 mr-3`}></i>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <div className="flex-grow overflow-hidden">
        {highValue && (
          <div className="mb-4">
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm text-gray-400">High</span>
              <div className="flex items-center">
                <i className="fas fa-arrow-up text-green-500 mr-2"></i>
                <span className="text-2xl font-bold">
                  {highValue.value.toFixed(1)}{unit}
                </span>
              </div>
            </div>
            <div className="text-xs text-gray-500">
              {formatDateTime(highValue.timestamp)}
            </div>
          </div>
        )}
        {lowValue && (
          <div className="mb-4">
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm text-gray-400">Low</span>
              <div className="flex items-center">
                <i className="fas fa-arrow-down text-red-500 mr-2"></i>
                <span className="text-2xl font-bold">
                  {lowValue.value.toFixed(1)}{unit}
                </span>
              </div>
            </div>
            <div className="text-xs text-gray-500">
              {formatDateTime(lowValue.timestamp)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ChartContainer = ({ 
  timeRange, 
  onTimeRangeChange, 
  selectedDate, 
  onDateChange,
  startDate,
  endDate,
  isLoading: parentIsLoading,
  setIsLoading: parentSetIsLoading,
  authToken,
  selectedDevice,
  forceUpdate = 0  // Add this prop
}) => {
  const [isLoading, setIsLoading] = useState(parentIsLoading);
  const [currentViewDate, setCurrentViewDate] = useState(null);
  const [chartData, setChartData] = useState({
    temperature: { data: [], startDate: null, endDate: null },
    humidity: { data: [], startDate: null, endDate: null },
    pressure: { data: [], startDate: null, endDate: null },
    airQuality: { data: [], startDate: null, endDate: null },
    powerStatus: { data: [], startDate: null, endDate: null }
  });
  const [isMobileTimeMenuOpen, setIsMobileTimeMenuOpen] = useState(false);

  const getNextValidDate = (baseDate, direction, range) => {
    const newDate = new Date(baseDate);
    
    switch(range) {
      case 'hour':
        // For hour view, we need to handle partial hours
        newDate.setHours(newDate.getHours() + direction);
        // Don't reset minutes for better partial hour handling
        break;
      case 'day':
        newDate.setDate(newDate.getDate() + direction);
        newDate.setHours(0, 0, 0, 0);
        break;
      case 'month':
        newDate.setMonth(newDate.getMonth() + direction);
        newDate.setDate(1);
        newDate.setHours(0, 0, 0, 0);
        break;
    }
    
    return newDate;
  };

  useEffect(() => {
    // Only set currentViewDate if it hasn't been set yet
    if (currentViewDate) return;
  
    if (selectedDate && !isNaN(new Date(selectedDate).getTime())) {
      let initialDate = new Date(selectedDate);
  
      if (initialDate.getFullYear() < 2020) {
        initialDate = new Date();
      }
  
      if (timeRange === 'hour') {
        initialDate.setMinutes(0, 0, 0);
      } else if (timeRange === 'day') {
        initialDate.setHours(0, 0, 0, 0);
      } else if (timeRange === 'month') {
        initialDate.setDate(1);
        initialDate.setHours(0, 0, 0, 0);
      }
  
      setCurrentViewDate(initialDate);
    } else {
      const now = new Date();
      setCurrentViewDate(now);
    }
  }, [selectedDate, timeRange, currentViewDate]);
  

  const fetchChartData = async (type, range, date) => {
    let aggregation = '';
    switch(range) {
      case 'hour':
        aggregation = '5min';
        break;
      case 'day':
        aggregation = '1hr';
        break;
      case 'month':
        aggregation = '1d';
        break;
      default:
        aggregation = '5min';
    }
  
    let start = new Date(date);
    let end = new Date(date);
  
    if (range === 'hour') {
      start.setMinutes(0, 0, 0);
      end.setMinutes(59, 59, 0);
    } else if (range === 'day') {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
    } else if (range === 'month') {
      start = new Date(start.getFullYear(), start.getMonth(), 1);
      end = new Date(start.getFullYear(), start.getMonth() + 1, 0, 23, 59, 59, 999);
    }
  
    // Map the type to the actual API metric name
    const metricName = type === 'airQuality' ? 'air_quality' : 
                      type === 'powerStatus' ? 'power_status' : 
                      type;
  
    // Build URL parameters
    let urlParams = new URLSearchParams({
      start_date: start.toISOString(),
      end_date: end.toISOString(),
      interval: aggregation,
      metrics: metricName
    });
  
    // Only add timezone offset for monthly view
    if (range === 'month') {
      // Get local timezone offset in minutes
      // getTimezoneOffset() returns the offset in minutes, positive for negative UTC offsets
      // and negative for positive UTC offsets, so we need to invert it
      const tzOffset = -new Date().getTimezoneOffset();
      urlParams.append('tz_offset', tzOffset.toString());
    }
  
    const url = `https://cabinpulse.com/api/data/timeseries-data?${urlParams.toString()}`;
  
    try {
      const response = await fetch(url, { 
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Device-ID': selectedDevice,
          'Content-Type': 'application/json'
        } 
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch ${type} data`);
      }
  
      const responseData = await response.json();
  
      const transformedData = responseData.data.map(item => ({
        timestamp: item.timestamp,
        value: type === 'airQuality' ? item.air_quality : 
               type === 'powerStatus' ? item.power_status :
               item[type]
      }));
  
      return {
        data: transformedData,
        startDate: responseData.start_date,
        endDate: responseData.end_date
      };
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
      return {
        data: [],
        startDate: start.toISOString(),
        endDate: end.toISOString()
      };
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (selectedDate && timeRange) {
        setIsLoading(true);
        try {
          const [
            temperatureData,
            humidityData,
            pressureData,
            airQualityData,
            powerStatusData
          ] = await Promise.all([
            fetchChartData('temperature', timeRange, selectedDate),
            fetchChartData('humidity', timeRange, selectedDate),
            fetchChartData('pressure', timeRange, selectedDate),
            fetchChartData('airQuality', timeRange, selectedDate),
            fetchChartData('powerStatus', timeRange, selectedDate)
          ]);

          setChartData({
            temperature: temperatureData,
            humidity: humidityData,
            pressure: pressureData,
            airQuality: airQualityData,
            powerStatus: powerStatusData
          });
        } catch (error) {
          console.error('Error fetching chart data:', error);
        } finally {
          setIsLoading(false);
          if (parentSetIsLoading) {
            parentSetIsLoading(false);
          }
        }
      }
    };

    loadData();
  }, [timeRange, selectedDate, authToken, selectedDevice, forceUpdate]);

  


  const canNavigate = (direction) => {
    if (!currentViewDate) return false;
    if (!startDate || !endDate) return true;
    
    const nextDate = getNextValidDate(currentViewDate, direction, timeRange);
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // For backward navigation
    if (direction < 0) {
      if (timeRange === 'hour') {
        // Instead of checking the start of the hour, check the end
        const nextHourEnd = new Date(nextDate);
        nextHourEnd.setMinutes(59, 59, 999);
        return nextHourEnd >= start;
      }
      return nextDate >= start;
    }
    
    // For forward navigation
    return nextDate <= end;
  };

// Add a useEffect to properly initialize currentViewDate
useEffect(() => {
  if (selectedDate && timeRange && !currentViewDate) {
    const initialDate = new Date(selectedDate);
    if (timeRange === 'hour') {
      initialDate.setMinutes(0, 0, 0);
    } else if (timeRange === 'day') {
      initialDate.setHours(0, 0, 0, 0);
    } else if (timeRange === 'month') {
      initialDate.setDate(1);
      initialDate.setHours(0, 0, 0, 0);
    }
    setCurrentViewDate(initialDate);
  }
}, [selectedDate, timeRange, currentViewDate]);

  const navigateDate = (direction) => {
    if (!currentViewDate || !canNavigate(direction)) return;
    
    const newDate = getNextValidDate(currentViewDate, direction, timeRange);
    setCurrentViewDate(newDate);
    onDateChange(newDate.toISOString());
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    switch(timeRange) {
      case 'hour':
        return date.toLocaleString('en-US', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      case 'day':
        return date.toLocaleString('en-US', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric' 
        });
      case 'month':
        return date.toLocaleString('en-US', { 
          year: 'numeric', 
          month: 'long'
        });
      default:
        return date.toLocaleString();
    }
  };

  const handleNowClick = () => {
    const endDateTime = new Date(endDate);
    
    // Adjust the date based on the current timeRange
    let newDate = new Date(endDateTime);
    switch(timeRange) {
      case 'hour':
        newDate.setMinutes(0, 0, 0);
        break;
      case 'day':
        newDate.setHours(0, 0, 0, 0);
        break;
      case 'month':
        newDate.setDate(1);
        newDate.setHours(0, 0, 0, 0);
        break;
    }
    
    setCurrentViewDate(newDate);
    onDateChange(newDate.toISOString());
  };

  if (!currentViewDate || !startDate || !endDate) {
    return <div>Loading...</div>;
  }

  const canNavigateBackward = canNavigate(-1);
  const canNavigateForward = canNavigate(1);

  return (
    <div className="bg-secondary-800 rounded-lg p-6 mb-8">
      <div className="flex items-center mb-6">
        <i className="fas fa-chart-line text-2xl text-accent-500 mr-3"></i>
        <h2 className="text-2xl font-bold">Historical Data</h2>
      </div>

      {/* Time Navigation Controls */}
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center mb-6">
        {/* Date Navigation - Full Width on Mobile */}
        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center w-full md:w-auto">
          <div className="flex items-center justify-between w-full">
            <Button 
              onClick={() => navigateDate(-1)} 
              disabled={!canNavigateBackward}
              className={`${!canNavigateBackward ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ChevronLeft size={20} />
            </Button>
            <span className="mx-4 text-lg font-semibold">
              {formatDate(currentViewDate)}
            </span>
            <Button 
              onClick={() => navigateDate(1)} 
              disabled={!canNavigateForward}
              className={`${!canNavigateForward ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <ChevronRight size={20} />
            </Button>
          </div>
          
          {/* Mobile Now Button */}
          <div className="md:hidden flex justify-center w-full">
            <Button
              onClick={handleNowClick}
              disabled={!canNavigateForward}
              className={`w-full ${!canNavigateForward ? 'opacity-50 cursor-not-allowed' : 'bg-accent-500 hover:bg-accent-600'}`}
            >
              <Forward size={16} className="mr-2" />
              <span>Latest</span>
            </Button>
          </div>

          {/* Desktop Now Button */}
          <Button
            onClick={handleNowClick}
            disabled={!canNavigateForward}
            className={`hidden ml-2 md:flex ${!canNavigateForward ? 'opacity-50 cursor-not-allowed' : 'bg-accent-500 hover:bg-accent-600'}`}
          >
            <Forward size={16} />
            <span className="ml-2">Latest</span>
          </Button>
        </div>

        {/* Mobile Time Range Selector */}
        <div className="md:hidden flex flex-col w-full">
          <button
            onClick={() => setIsMobileTimeMenuOpen(!isMobileTimeMenuOpen)}
            className="flex items-center justify-between w-full bg-secondary-700 p-3 rounded-lg"
          >
            <span className="flex items-center">
              <Clock size={16} className="mr-2" />
              <span className="capitalize">{timeRange} View</span>
            </span>
            <ChevronDown
              size={16}
              className={`transform transition-transform duration-200 ${
                isMobileTimeMenuOpen ? 'rotate-180' : ''
              }`}
            />
          </button>
          
          <div
            className={`
              mt-2 overflow-hidden transition-all duration-200 ease-in-out
              ${isMobileTimeMenuOpen ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}
            `}
          >
            <div className="bg-secondary-700 rounded-lg p-2 space-y-2">
              {['hour', 'day', 'month'].map((range) => (
                <button
                  key={range}
                  onClick={() => {
                    onTimeRangeChange(range);
                    setIsMobileTimeMenuOpen(false);
                  }}
                  className={`
                    w-full p-2 rounded-lg flex items-center justify-between
                    ${timeRange === range ? 'bg-accent-500' : 'bg-secondary-600 hover:bg-secondary-500'}
                  `}
                >
                  <span className="capitalize">{range}</span>
                  {timeRange === range && <Check size={16} />}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Desktop Time Range Selector */}
        <div className="hidden md:flex space-x-2">
          {['hour', 'day', 'month'].map((range) => (
            <Button 
              key={range}
              onClick={() => onTimeRangeChange(range)}
              className={timeRange === range ? 'bg-highlight-500' : 'bg-accent-500'}
            >
              {range.charAt(0).toUpperCase() + range.slice(1)}
            </Button>
          ))}
        </div>
      </div>

      {/* Rest of the component remains the same */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        <div className="md:col-span-2 xl:col-span-3 grid grid-cols-1 md:grid-cols-2 gap-8">
          <ChartComponent 
            title="Temperature"
            icon="temperature-high"
            data={chartData.temperature.data}
            startDate={chartData.temperature.startDate}
            endDate={chartData.temperature.endDate}
            type="line"
            isLoading={isLoading}
            timeRange={timeRange}
            unit="°C"
          />
          <ChartComponent 
            title="Humidity"
            icon="tint"
            data={chartData.humidity.data}
            startDate={chartData.humidity.startDate}
            endDate={chartData.humidity.endDate}
            type="line"
            isLoading={isLoading}
            timeRange={timeRange}
            unit="%"
          />
        </div>
        <ChartComponent 
          title="Air Quality"
          icon="wind"
          data={chartData.airQuality.data}
          startDate={chartData.airQuality.startDate}
          endDate={chartData.airQuality.endDate}
          type="line"
          metric="air_quality"
          isLoading={isLoading}
          timeRange={timeRange}
          unit=" kΩ"
        />
        <ChartComponent 
          title="Pressure"
          icon="tachometer-alt"
          data={chartData.pressure.data}
          startDate={chartData.pressure.startDate}
          endDate={chartData.pressure.endDate}
          type="line"
          isLoading={isLoading}
          timeRange={timeRange}
          unit=" hPa"
        />
        <ChartComponent 
          title="Power Status"
          icon="bolt"
          data={chartData.powerStatus.data}
          startDate={chartData.powerStatus.startDate}
          endDate={chartData.powerStatus.endDate}
          type="line"
          metric="power_status"
          isLoading={isLoading}
          timeRange={timeRange}
        />
      </div>
    </div>
  );
};

// Login Component
const Login = ({ onLogin, setAppState }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://cabinpulse.com/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token); // Store the token
      onLogin(data.token);
    } catch (error) {
      setError('Login failed. Please check your credentials and try again.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-primary-900">
      <div className="bg-secondary-800 rounded-lg shadow-lg p-8 w-full max-w-md">
        <div className="flex flex-col items-center mb-6">
          <img src="/cpicon.png" alt="CabinPulse Logo" className="w-16 h-16 mb-4" />
          <h2 className="text-3xl font-bold text-white">Welcome Back</h2>
          <p className="text-gray-400">Please login to your account</p>
        </div>
        {error && <Alert variant="destructive">{error}</Alert>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
            <input 
              type="email" 
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
              placeholder="you@example.com"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">Password</label>
            <input 
              type="password" 
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
              placeholder="••••••••"
            />
          </div>
          <Button type="submit" className="w-full text-center justify-center">
            Login
          </Button>
        </form>
        <div className="mt-4 flex justify-center">
        <button
            onClick={() => setAppState('forgot-password')}
            className="text-sm text-white hover:underline"
          >
            Forgot Password?
          </button>
        </div>
        <div className="mt-6 text-center">
          <p className="text-gray-400 text-sm">
            &copy; {new Date().getFullYear()} CabinPulse. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

// Add a new PowerOutageInsightCard component
const PowerOutageInsightCard = ({ title, icon, outages, totalOutages, totalDuration }) => {
  // Helper function to format duration
  const formatDuration = (minutes) => {
    if (minutes < 60) {
      return `${minutes} minutes`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours < 24) {
      return `${hours}h ${remainingMinutes}m`;
    }
    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;
    return `${days}d ${remainingHours}h`;
  };

  // Get the longest outage
  const longestOutage = outages.reduce((longest, current) => {
    const currentDuration = current.duration;
    return currentDuration > (longest?.duration || 0) ? current : longest;
  }, null);

  return (
    <div className="bg-primary-900 rounded-lg p-6 flex flex-col h-full transition-all duration-300 hover:shadow-lg hover:scale-105">
      <div className="flex items-center mb-4">
        <i className={`fas fa-${icon} text-2xl text-accent-500 mr-3`}></i>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <div className="flex-grow overflow-hidden">
        <div className="mb-4">
          <div className="flex items-center justify-between mb-1">
            <span className="text-sm text-gray-400">Total Outages</span>
            <span className="text-2xl font-bold">{totalOutages}</span>
          </div>
          <div className="flex items-center justify-between mb-1">
            <span className="text-sm text-gray-400">Total Duration</span>
            <span className="text-xl font-bold">{formatDuration(totalDuration)}</span>
          </div>
        </div>
        {longestOutage && (
          <div className="mt-4">
            <div className="text-sm text-gray-400 mb-1">Longest Outage</div>
            <div className="text-sm">
              <div>{formatDateTime(longestOutage.start)}</div>
              <div className="text-accent-500 font-bold">
                {formatDuration(longestOutage.duration)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


const AddDeviceView = ({ onDeviceAdded }) => {
  const [activationCode, setActivationCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [step, setStep] = useState('initial');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleActivationCodeChange = (e) => {
    // Only allow alphanumeric characters, convert to uppercase
    const value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    setActivationCode(value.slice(0, 8)); // Limit to 8 characters
  };

  const handleActivation = async () => {
    if (activationCode.length !== 8) {
      setError('Activation code must be 8 characters long.');
      return;
    }
    
    setError('');
    setIsSubmitting(true);

    try {
      // First verify the activation code
      const verifyResponse = await fetch('https://cabinpulse.com/api/devices/verify', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ activation_code: activationCode })
      });

      if (!verifyResponse.ok) {
        throw new Error('Invalid activation code');
      }

      setStep('name');
    } catch (error) {
      setError(error.message || 'Failed to verify activation code');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameSubmit = async () => {
    if (!deviceName.trim()) {
      setError('Please enter a device name');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://cabinpulse.com/api/devices/activate', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          activation_code: activationCode,
          name: deviceName
        })
      });

      if (!response.ok) {
        throw new Error('Failed to activate device');
      }

      const data = await response.json();
      onDeviceAdded(data.device);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setError(error.message || 'Failed to activate device');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-primary-900 flex items-center justify-center p-4">
      <div className="bg-secondary-800 rounded-lg shadow-xl p-8 max-w-md w-full">
        {/* Header */}
        <div className="text-center mb-8">
          <div className="bg-accent-500 rounded-full h-16 w-16 flex items-center justify-center mx-auto mb-4">
            <Plus size={32} className="text-white" />
          </div>
          <h2 className="text-2xl font-bold text-white mb-2">
            {step === 'initial' ? 'Add Your First Device' : 
             step === 'activate' ? 'Enter Activation Code' :
             'Name Your Device'}
          </h2>
          <p className="text-gray-400">
            {step === 'initial' ? 'Get started with CabinPulse by adding your first device' :
             step === 'activate' ? 'Enter the 8-digit code from your CabinPulse device' :
             'Choose a name to identify your device'}
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 rounded-md p-4 mb-6 flex items-center">
            <AlertCircle size={20} className="text-red-500 mr-2" />
            <span className="text-red-500">{error}</span>
          </div>
        )}

        {/* Content */}
        {step === 'initial' && (
          <button
            onClick={() => setStep('activate')}
            className="w-full bg-accent-500 hover:bg-accent-600 text-white font-bold py-4 px-6 rounded-lg flex items-center justify-center"
          >
            <Plus size={20} className="mr-2" />
            Add Device
          </button>
        )}

        {step === 'activate' && (
          <div>
            <div className="mb-6">
              <label htmlFor="activation-code" className="block text-sm font-medium text-gray-400 mb-2">
                Activation Code
              </label>
              <input
                id="activation-code"
                type="text"
                maxLength="8"
                className="w-full bg-primary-700 text-white rounded-lg px-4 py-3 text-center font-mono text-xl tracking-wider"
                placeholder="XXXXXXXX"
                value={activationCode}
                onChange={handleActivationCodeChange}
              />
              <p className="mt-2 text-sm text-gray-400 text-center">
                Enter the 8-character code provided with your device
              </p>
            </div>
            <button
              onClick={handleActivation}
              disabled={isSubmitting || activationCode.length !== 8}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white font-bold py-4 px-6 rounded-lg flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
              ) : (
                'Verify Code'
              )}
            </button>
          </div>
        )}

        {step === 'name' && (
          <div>
            <div className="mb-6">
              <label htmlFor="device-name" className="block text-sm font-medium text-gray-400 mb-2">
                Device Name
              </label>
              <input
                id="device-name"
                type="text"
                className="w-full bg-primary-700 text-white rounded-lg px-4 py-3"
                placeholder="e.g., Lake House"
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
              />
            </div>
            <button
              onClick={handleNameSubmit}
              disabled={isSubmitting || !deviceName.trim()}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white font-bold py-4 px-6 rounded-lg flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
              ) : (
                'Activate Device'
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ForgotPassword = ({ onBackToLogin, setAppState }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://cabinpulse.com/api/users/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        throw new Error('Failed to process request');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-primary-900">
      <div className="bg-secondary-800 rounded-lg shadow-lg p-8 w-full max-w-md">
        <div className="flex flex-col items-center mb-6">
          <img src="/cpicon.png" alt="CabinPulse Logo" className="w-16 h-16 mb-4" />
          <h2 className="text-3xl font-bold text-white">Reset Password</h2>
          <p className="text-gray-400">Enter your email to receive reset instructions</p>
        </div>

        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded mb-4 flex items-center">
            <AlertCircle size={20} className="mr-2" />
            {error}
          </div>
        )}

        {success ? (
          <div className="space-y-6">
            <div className="bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-4 py-3 rounded">
              <div className="flex items-center mb-2">
                <Mail className="mr-2" />
                <span className="font-semibold">Check your email</span>
              </div>
              <p>If your email is registered, password reset instructions have been sent.</p>
            </div>
            <button
              onClick={onBackToLogin}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white font-bold py-2 px-4 rounded transition-colors"
            >
              Back to Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
                placeholder="you@example.com"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white font-bold py-2 px-4 rounded transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mx-auto" />
              ) : (
                'Send Reset Instructions'
              )}
            </button>
            <button
              type="button"
              onClick={onBackToLogin}
              className="w-full bg-secondary-700 hover:bg-secondary-600 text-white font-bold py-2 px-4 rounded transition-colors"
            >
              Back to Login
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

const ResetPassword = ({ token, onResetComplete, setAppState }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [isCheckingToken, setIsCheckingToken] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setError('Invalid reset token');
        setIsCheckingToken(false);
        return;
      }

      try {
        const response = await fetch('https://cabinpulse.com/api/users/verify-reset-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (response.ok) {
          setIsValidToken(true);
        } else {
          setError('Invalid or expired reset token');
        }
      } catch (error) {
        setError('Error verifying reset token');
      } finally {
        setIsCheckingToken(false);
      }
    };

    verifyToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://cabinpulse.com/api/users/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          password,
        }),
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          onResetComplete();
        }, 3000);
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Failed to reset password');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isCheckingToken) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-primary-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-accent-500"></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-primary-900">
      <div className="bg-secondary-800 rounded-lg shadow-lg p-8 w-full max-w-md">
        <div className="flex flex-col items-center mb-6">
          <img src="/cpicon.png" alt="CabinPulse Logo" className="w-16 h-16 mb-4" />
          <h2 className="text-3xl font-bold text-white">Reset Password</h2>
          {!success && <p className="text-gray-400">Enter your new password</p>}
        </div>

        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded mb-4 flex items-center">
            <AlertCircle size={20} className="mr-2" />
            {error}
          </div>
        )}

        {success ? (
          <div className="space-y-4">
            <div className="bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-4 py-3 rounded text-center">
              <p>Password reset successful!</p>
              <p className="text-sm mt-2">Redirecting to login...</p>
            </div>
          </div>
        ) : isValidToken ? (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
                New Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
                placeholder="••••••••"
                minLength={8}
              />
            </div>
            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-300 mb-1">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full bg-primary-700 text-white rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent-500"
                placeholder="••••••••"
                minLength={8}
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-accent-500 hover:bg-accent-600 text-white font-bold py-2 px-4 rounded transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mx-auto" />
              ) : (
                'Reset Password'
              )}
            </button>
          </form>
        ) : (
          <div className="text-center space-y-4">
            <p className="text-red-500">This password reset link is invalid or has expired.</p>
            <button
              onClick={() => setAppState('forgot-password')}
              className="text-white hover:text-accent-400 transition-colors"
            >
              Request a new reset link
            </button>
          </div>
        )}

        <div className="mt-4 text-center">
          <button
            onClick={() => setAppState('login')}
            className="text-white hover:text-accent-400 transition-colors"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

const InitialPowerView = ({ deviceName, onContinue }) => {
  const [confirmed, setConfirmed] = useState(false);
  
  return (
    <div className="min-h-screen bg-primary-900 flex items-center justify-center p-4">
      <div className="bg-secondary-800 rounded-lg p-8 max-w-2xl w-full text-center">
        <div className="flex justify-center mb-6">
          <div className="relative">
            <img src="/cpicon.png" alt="CabinPulse Logo" className="w-20 h-20" />
            <div className="absolute -bottom-2 -right-2 bg-red-500 rounded-full p-2">
              <Power className="w-4 h-4 text-white" />
            </div>
          </div>
        </div>
        
        <h1 className="text-4xl font-bold mb-3 bg-gradient-to-r from-accent-500 to-white text-transparent bg-clip-text">
          Let's Get Started!
        </h1>
        
        <p className="text-xl text-gray-400 mb-8">
          {deviceName} needs to be powered up before we can continue
        </p>
        
        <div className="bg-primary-900 rounded-lg p-6 mb-8">
          <div className="flex items-center justify-center mb-4">
            <Zap size={32} className="text-yellow-500 mr-2" />
            <h2 className="text-2xl font-semibold">Power Up Instructions</h2>
          </div>
          
          <ol className="text-left space-y-4 mb-6">
            <li className="flex items-start">
              <span className="bg-accent-500 rounded-full w-6 h-6 flex items-center justify-center mr-3 mt-1">1</span>
              <span>Locate the power adapter that came with your CabinPulse device</span>
            </li>
            <li className="flex items-start">
              <span className="bg-accent-500 rounded-full w-6 h-6 flex items-center justify-center mr-3 mt-1">2</span>
              <span>Connect the power adapter to your device</span>
            </li>
            <li className="flex items-start">
              <span className="bg-accent-500 rounded-full w-6 h-6 flex items-center justify-center mr-3 mt-1">3</span>
              <span>Plug the adapter into a power outlet</span>
            </li>
          </ol>
          
          <div className="space-y-4">
            <div className="bg-yellow-500 bg-opacity-10 border border-yellow-500 rounded-lg p-4 flex items-start">
              <InfoIcon size={20} className="text-yellow-500 mr-2 mt-1 flex-shrink-0" />
              <p className="text-sm text-yellow-500">
                For optimal performance, place your device in an open area away from metal objects and other electronic devices that might interfere with the cellular signal.
              </p>
            </div>

            <div className="bg-blue-500 bg-opacity-10 border border-blue-500 rounded-lg p-4 flex items-start">
              <InfoIcon size={20} className="text-blue-500 mr-2 mt-1 flex-shrink-0" />
              <p className="text-sm text-blue-500">
                Important: After plugging in your device, please wait up to 30 seconds for the LED light to start glowing. Do not adjust or replug the power cord during this time.
              </p>
            </div>
          </div>
        </div>
        
        <div className="flex items-center justify-center gap-3 mb-6">
          <input
            type="checkbox"
            id="confirm"
            checked={confirmed}
            onChange={(e) => setConfirmed(e.target.checked)}
            className="w-5 h-5 rounded border-gray-300 text-accent-500 focus:ring-accent-500"
          />
          <label htmlFor="confirm" className="text-gray-300">
            I have plugged in my device
          </label>
        </div>
        
        <button
          onClick={onContinue}
          disabled={!confirmed}
          className={`
            w-full bg-accent-500 text-white font-semibold py-4 px-6 rounded-lg
            flex items-center justify-center gap-2 transition-all
            ${!confirmed ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-600'}
          `}
        >
          <span>Continue Setup</span>
          <ArrowRight size={20} />
        </button>
      </div>
    </div>
  );
};

const InitialSetupView = ({ deviceName, deviceId, onDataAvailable }) => { 
  const [checkCount, setCheckCount] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  
  const steps = [
    { icon: Check, title: "Device Activated", description: "Registration complete", color: "emerald" },
    { icon: Signal, title: "Establishing Connection", description: "Connecting to cellular network", color: "blue" },
    { icon: Cloud, title: "Cloud Setup", description: "Configuring cloud services", color: "violet" },
    { icon: Server, title: "Initializing Sensors", description: "Preparing monitoring systems", color: "amber" },
    { icon: Zap, title: "Awaiting First Reading", description: "Initial data incoming", color: "rose" }
  ];

  const checkMetadata = async () => {
    setIsChecking(true);
    try {
      const response = await fetch('https://cabinpulse.com/api/data/metadata', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Device-ID': deviceId,
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      
      if (!data.error || data.error.code !== 'NOT_FOUND') {
        onDataAvailable();
      }
    } catch (error) {
      console.error('Error checking metadata:', error);
    } finally {
      setIsChecking(false);
      setCheckCount(prev => prev + 1);
    }
  };

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setCurrentStep(prev => {
        if (prev < steps.length - 1) return prev + 1;
        setShowRefreshButton(true);
        return prev;
      });
    }, 3000);

    const checkInterval = setInterval(checkMetadata, 60000);
    
    return () => {
      clearInterval(progressInterval);
      clearInterval(checkInterval);
    };
  }, []);

  return (
    <div className="min-h-screen bg-primary-900 flex items-center justify-center p-4">
      <div className="bg-secondary-800 rounded-lg p-8 max-w-3xl w-full">
        <div className="text-center mb-12">
          <div className="flex justify-center mb-6">
            <div className="relative">
              <img src="/cpicon.png" alt="CabinPulse Logo" className="w-20 h-20" />
              <div className="absolute -bottom-2 -right-2 bg-accent-500 rounded-full p-2">
                <Wifi className="w-4 h-4 text-white animate-pulse" />
              </div>
            </div>
          </div>
          <h1 className="text-4xl font-bold mb-3 bg-gradient-to-r from-accent-500 to-white text-transparent bg-clip-text">
            Welcome to CabinPulse!
          </h1>
          <p className="text-xl text-gray-400">
            {deviceName} is initializing...
          </p>
        </div>
        
        <div className="space-y-4">
          {steps.map((step, index) => (
            <div 
              key={index}
              className={`transition-all duration-500 transform ${
                index <= currentStep 
                  ? 'translate-x-0 opacity-100' 
                  : 'translate-x-8 opacity-0'
              }`}
            >
              <div className={`
                bg-primary-900 rounded-lg p-6 flex items-center gap-4
                ${index === currentStep && 'ring-2 ring-accent-500 shadow-lg'}
                transition-all duration-300
              `}>
                <div className={`
                  bg-${step.color}-500 bg-opacity-20 p-3 rounded-full
                  ${index === currentStep && 'animate-pulse'}
                `}>
                  <step.icon 
                    size={24} 
                    className={`text-${step.color}-500 ${
                      index === currentStep && step.icon === RefreshCw 
                        ? 'animate-spin'
                        : ''
                    }`}
                  />
                </div>
                <div className="flex-grow">
                  <h3 className="font-semibold mb-1 flex items-center gap-2">
                    {step.title}
                    {index < currentStep && (
                      <Check size={16} className="text-green-500" />
                    )}
                  </h3>
                  <p className="text-sm text-gray-400">{step.description}</p>
                </div>
                {index === steps.length - 1 && (
                  <div className="text-xs text-gray-500">
                    {checkCount === 0 ? 'Just started' : `Checked ${checkCount} times`}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {showRefreshButton && (
          <div className="mt-8 flex justify-center">
            <button
              onClick={checkMetadata}
              disabled={isChecking}
              className={`
                bg-accent-500 hover:bg-accent-600 text-white font-semibold
                px-6 py-3 rounded-lg flex items-center gap-2 transition-all
                ${isChecking ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}
              `}
            >
              <RefreshCw className={`w-5 h-5 ${isChecking ? 'animate-spin' : ''}`} />
              {isChecking ? 'Checking...' : 'Check Now'}
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        )}

        <div className="bg-primary-900 rounded-lg p-6 text-center mt-8">
          <p className="text-gray-400">
            Initial setup can take up to 20 minutes. We'll automatically refresh when ready.
          </p>
        </div>
      </div>
    </div>
  );
};

// Main App Component
const App = () => {
  const [currentStats, setCurrentStats] = useState({});
  const [temperatureData, setTemperatureData] = useState([]);
  const [powerOutageData, setPowerOutageData] = useState([]);
  const [humidityData, setHumidityData] = useState([]);
  const [smokeGasData, setSmokeGasData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [timeRange, setTimeRange] = useState('hour');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date().toISOString());
  const [selectedDate, setSelectedDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [insightsStartDate, setInsightsStartDate] = useState('');
  const [insightsEndDate, setInsightsEndDate] = useState('');
  const [insights, setInsights] = useState(null);
  const [isInsightsLoading, setIsInsightsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [airQualityData, setAirQualityData] = useState([]);
  const [pressureData, setPressureData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);
  const [appState, setAppState] = useState('login'); // 'login', 'activate', 'dashboard', 'forgot-password', 'reset-password'
  const [resetToken, setResetToken] = useState('');
  const [forceUpdate, setForceUpdate] = useState(0);
  const [initialSetupStep, setInitialSetupStep] = useState('power-up');
  
  
  const handleDeviceAdded = (newDevice) => {
    setDevices(prevDevices => [...prevDevices, newDevice]);
  };

  useEffect(() => {
    // Check if we're accessing a reset password URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    
    if (token) {
      setResetToken(token);
      setAppState('reset-password');
      // Clear the URL parameters
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);


  const handleRefresh = async () => {
    if (!selectedDevice) return;
    
    try {
      // Get new metadata to ensure we have the latest end date
      const metadataResponse = await fetch('https://cabinpulse.com/api/data/metadata', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Device-ID': selectedDevice,
          'Content-Type': 'application/json'
        }
      });
  
      if (!metadataResponse.ok) {
        throw new Error('Failed to fetch metadata');
      }
  
      const metadata = await metadataResponse.json();
      const latestDataTime = new Date(metadata.data_range.end_date);
      
      // Update the end date
      setEndDate(latestDataTime.toISOString());
  
      // Adjust the selected date to the latest time based on current timeRange
      let newSelectedDate = new Date(latestDataTime);
      switch(timeRange) {
        case 'hour':
          newSelectedDate.setMinutes(0, 0, 0);
          break;
        case 'day':
          newSelectedDate.setHours(0, 0, 0, 0);
          break;
        case 'month':
          newSelectedDate.setDate(1);
          newSelectedDate.setHours(0, 0, 0, 0);
          break;
      }
  
      // Update selected date to trigger chart refresh
      setSelectedDate(newSelectedDate.toISOString());
      
      // Fetch current stats
      const stats = await fetchCurrentStats(selectedDevice);
      setCurrentStats(stats);
      setLastUpdated(stats.timestamp || latestDataTime.toISOString());
      
      // Force update the charts
      setForceUpdate(prev => prev + 1);
      
      return true;
    } catch (error) {
      console.error('Error refreshing data:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  
  const refreshAllComponents = async () => {
    try {
      // Get new metadata to ensure we have the latest end date
      const metadataResponse = await fetch('https://cabinpulse.com/api/data/metadata', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Device-ID': selectedDevice,
          'Content-Type': 'application/json'
        }
      });
  
      if (!metadataResponse.ok) {
        throw new Error('Failed to fetch metadata');
      }
  
      const metadata = await metadataResponse.json();
      const latestDataTime = new Date(metadata.data_range.end_date);
      
      // Set loading states
      setIsLoading(true);
      setIsInsightsLoading(true);
  
      // Update the end date
      setEndDate(latestDataTime.toISOString());
  
      // Adjust the selected date to the latest time based on current timeRange
      let newSelectedDate = new Date(latestDataTime);
      switch(timeRange) {
        case 'hour':
          newSelectedDate.setMinutes(0, 0, 0);
          break;
        case 'day':
          newSelectedDate.setHours(0, 0, 0, 0);
          break;
        case 'month':
          newSelectedDate.setDate(1);
          newSelectedDate.setHours(0, 0, 0, 0);
          break;
      }
  
      // Update selected date to trigger chart refresh
      setSelectedDate(newSelectedDate.toISOString());
      
      // Update insights date range if needed
      if (insightsStartDate && insightsEndDate) {
        const newInsightsEnd = new Date(latestDataTime);
        const newInsightsStart = new Date(newInsightsEnd);
        newInsightsStart.setDate(newInsightsStart.getDate() - 1);
        
        setInsightsStartDate(newInsightsStart.toISOString().split('T')[0]);
        setInsightsEndDate(newInsightsEnd.toISOString().split('T')[0]);
  
        const newInsights = await fetchInsights(
          newInsightsStart.toISOString(),
          newInsightsEnd.toISOString(),
          selectedDevice
        );
        
        if (newInsights) {
          setInsights(newInsights);
        }
      }
      
      // Fetch current stats
      const stats = await fetchCurrentStats(selectedDevice);
      setCurrentStats(stats);
      setLastUpdated(stats.timestamp || latestDataTime.toISOString());
      
      return true;
    } catch (error) {
      console.error('Error refreshing components:', error);
      return false;
    } finally {
      setIsLoading(false);
      setIsInsightsLoading(false);
    }
  };



  // Replace with your actual auth token and device ID
  const AUTH_TOKEN = 'YOUR_AUTH_TOKEN';
  const DEVICE_ID = 'e00fce6842b9df5c48f22c91';

  const headers = {
    'Authorization': `Bearer${localStorage.getItem('token')}`,
    'Device-ID': `${DEVICE_ID}`,
    'Content-Type': 'application/json'
  };

  // Fetch Current Stats
  const fetchCurrentStats = async (deviceId) => {
    try {
      const response = await fetch('https://cabinpulse.com/api/data/current-data', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Device-ID': deviceId, // Use the passed device ID
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Failed to fetch current data');
      }
      
      const data = await response.json();
      return {
        temperature: data.temperature,
        humidity: data.humidity,
        pressure: data.pressure,
        air_quality: data.air_quality,
        power_status: data.power_status,
        timestamp: data.timestamp
      };
    } catch (error) {
      console.error('Error fetching current stats:', error);
      return {};
    }
  };


  const renderCharts = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
      <ChartComponent 
        title="Temperature"
        icon="temperature-high"
        data={temperatureData}
        type="line"
        isLoading={isLoading}
        timeRange={timeRange}
      />
      <ChartComponent 
        title="Humidity"
        icon="tint"
        data={humidityData}
        type="line"
        isLoading={isLoading}
        timeRange={timeRange}
      />
      <ChartComponent 
        title="Pressure"
        icon="tachometer-alt"
        data={pressureData}
        type="line"
        isLoading={isLoading}
        timeRange={timeRange}
      />
<ChartComponent 
  title="Power Status"
  icon="bolt"
  data={powerOutageData}
  type="line"
  metric="power_status"
  isLoading={isLoading}
  timeRange={timeRange}
/>

<ChartComponent 
  title="Air Quality"
  icon="wind"
  data={airQualityData}
  type="line"
  metric="air_quality"
  isLoading={isLoading}
  timeRange={timeRange}
/>
    </div>
  );

  // Fetch Available Date Range from Metadata
  const fetchAvailableDateRange = async () => {
    try {
      const response = await fetch('https://cabinpulse.com/api/data/metadata', {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch metadata');
      }
      const data = await response.json();
      return {
        startDate: data.data_start_date,
        endDate: data.data_end_date
      };
    } catch (error) {
      console.error('Error fetching date range:', error);
      return {};
    }
  };

  // Fetch Chart Data from Timeseries Data Endpoint
  const fetchChartData = async (type, range, date) => {
    let aggregation = '';
    switch(range) {
      case 'hour':
        aggregation = '5min';
        break;
      case 'day':
        aggregation = '1hr';
        break;
      case 'month':
        aggregation = '1d';
        break;
      default:
        aggregation = '5min';
    }
  
    let start = new Date(date);
    let end = new Date(date);
  
    if (range === 'hour') {
      start.setMinutes(0, 0, 0);
      end.setMinutes(59, 59, 0);
    } else if (range === 'day') {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
    } else if (range === 'month') {
      start = new Date(start.getFullYear(), start.getMonth(), 1);
      end = new Date(start.getFullYear(), start.getMonth() + 1, 0, 23, 59, 59, 999);
    }
  
    // Map the type to the actual API metric name
    const metricName = type === 'airQuality' ? 'air_quality' : 
                      type === 'powerStatus' ? 'power_status' : 
                      type;
  
    // Build URL parameters
    let urlParams = new URLSearchParams({
      start_date: start.toISOString(),
      end_date: end.toISOString(),
      interval: aggregation,
      metrics: metricName
    });
  
    // Only add timezone offset for monthly view
    if (range === 'month') {
      // Get local timezone offset in minutes
      // getTimezoneOffset() returns the offset in minutes, positive for negative UTC offsets
      // and negative for positive UTC offsets, so we need to invert it
      const tzOffset = -new Date().getTimezoneOffset();
      urlParams.append('tz_offset', tzOffset.toString());
    }
  
    const url = `https://cabinpulse.com/api/data/timeseries-data?${urlParams.toString()}`;
  
    try {
      const response = await fetch(url, { 
        headers: {
          'Authorization': `Bearer ${AUTH_TOKEN}`,
          'Device-ID': selectedDevice,
          'Content-Type': 'application/json'
        } 
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch ${type} data`);
      }
  
      const responseData = await response.json();
  
      const transformedData = responseData.data.map(item => ({
        timestamp: item.timestamp,
        value: type === 'airQuality' ? item.air_quality : 
               type === 'powerStatus' ? item.power_status :
               item[type]
      }));
  
      return {
        data: transformedData,
        startDate: responseData.start_date,
        endDate: responseData.end_date
      };
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
      return {
        data: [],
        startDate: start.toISOString(),
        endDate: end.toISOString()
      };
    }
  };
  

  // Fetch Insights
const fetchInsights = async (startDate, endDate, deviceId) => {
  if (!startDate || !endDate || !deviceId) {
    console.error('Invalid parameters for insights fetch');
    return null;
  }
  
  try {
    const url = `https://cabinpulse.com/api/data/insights?start_date=${new Date(startDate).toISOString()}&end_date=${new Date(endDate).toISOString()}`;
    
    const response = await fetch(url, { 
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Device-ID': deviceId,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch insights');
    }
    
    const data = await response.json();
    return data.insights;
  } catch (error) {
    console.error('Error fetching insights:', error);
    return null;
  }
};
  

  // Helper function to round numbers
  const roundNumber = (num, decimals) => {
    return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  };

  const handleAuthError = () => {
    localStorage.removeItem('token');
    setToken(null);
    setIsLoggedIn(false);
    setAppState('login');
  };

 // In your App.js, update the initial data loading useEffect:
 useEffect(() => {
  const loadInitialData = async () => {
    setIsLoading(true);
    setIsInsightsLoading(true);
    setIsLoadingDevices(true);
  
    try {
      // First, fetch user's devices
      const devicesResponse = await fetch('https://cabinpulse.com/api/devices', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      if (devicesResponse.status === 401) {
        handleAuthError();
        return;
      }
      
      if (devicesResponse.ok) {
        const devicesData = await devicesResponse.json();
        setDevices(devicesData);
        
        // Find the first activated device and set it as selected
        const firstActivatedDevice = devicesData.find(device => device.status === 'activated');
        
        if (firstActivatedDevice) {
          setSelectedDevice(firstActivatedDevice.device_id);
          
          try {
            const metadataResponse = await fetch('https://cabinpulse.com/api/data/metadata', { 
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Device-ID': firstActivatedDevice.device_id,
                'Content-Type': 'application/json'
              }
            });
  
            // If metadata response is 404 or returns an error code, show initial setup view
            if (!metadataResponse.ok || metadataResponse.status === 404) {
              setIsLoading(false);
              setIsInsightsLoading(false);
              setIsLoadingDevices(false);
              setAppState('initial-setup');
              return;
            }
            
            const metadata = await metadataResponse.json();
            
            // Also check for explicit error in response
            if (metadata.error?.code === 'NOT_FOUND') {
              setIsLoading(false);
              setIsInsightsLoading(false);
              setIsLoadingDevices(false);
              setAppState('initial-setup');
              return;
            }
            
            if (metadata.data_range && metadata.data_range.start_date && metadata.data_range.end_date) {
              // First set both boundary dates
              const dataStartDate = metadata.data_range.start_date;
              const dataEndDate = metadata.data_range.end_date;
              
              setStartDate(dataStartDate);
              setEndDate(dataEndDate);
              
              // Now set the selected date using the end date
              const endDateTime = new Date(dataEndDate);
              endDateTime.setMinutes(0, 0, 0);
              setSelectedDate(endDateTime.toISOString());
              
              const insightsEnd = new Date(metadata.data_range.end_date);
              const insightsStart = new Date(insightsEnd);
              insightsStart.setDate(insightsStart.getDate() - 1);
              
              setInsightsStartDate(insightsStart.toISOString().split('T')[0]);
              setInsightsEndDate(insightsEnd.toISOString().split('T')[0]);
              
              // Fetch insights for the last 24 hours
              const insightsData = await fetchInsights(
                insightsStart.toISOString(),
                insightsEnd.toISOString(),
                firstActivatedDevice.device_id
              );
              
              if (insightsData) {
                setInsights(insightsData);
              }
  
              // Fetch current stats
              const stats = await fetchCurrentStats(firstActivatedDevice.device_id);
              setCurrentStats(stats);
              setLastUpdated(stats.timestamp || metadata.data_range.end_date);
            }
          } catch (error) {
            console.error('Error fetching metadata:', error);
            // If metadata fetch fails, show initial setup view
            setIsLoading(false);
            setIsInsightsLoading(false);
            setIsLoadingDevices(false);
            setAppState('initial-setup');
            return;
          }
        }
      }
    } catch (error) {
      console.error('Error loading initial data:', error);
      // Set fallback dates if there's an error
      const today = new Date();
      const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
      setStartDate(oneMonthAgo.toISOString());
      setEndDate(today.toISOString());
      setSelectedDate(today.toISOString());
      setInsightsStartDate(oneMonthAgo.toISOString().split('T')[0]);
      setInsightsEndDate(today.toISOString().split('T')[0]);
    } finally {
      setIsLoading(false);
      setIsInsightsLoading(false);
      setIsLoadingDevices(false);
    }
  };

  if (isLoggedIn) {
    loadInitialData();
  }
}, [isLoggedIn]);



  // Fetch Chart Data when dependencies change
  useEffect(() => {
    const fetchData = async () => {
      if (selectedDate && timeRange && selectedDevice) {
        setIsLoading(true);
        try {
          const tempData = await fetchChartData('temperature', timeRange, selectedDate);
          const humidityData = await fetchChartData('humidity', timeRange, selectedDate);
          const pressureData = await fetchChartData('pressure', timeRange, selectedDate);
          const airQualityData = await fetchChartData('airQuality', timeRange, selectedDate);
          const powerData = await fetchChartData('powerStatus', timeRange, selectedDate);
          
          // Directly update state with what was returned (even if empty)
          setTemperatureData(tempData);
          setHumidityData(humidityData);
          setPressureData(pressureData);
          setAirQualityData(airQualityData);
          setPowerOutageData(powerData);
          
          // If no data is returned, you can choose to show "No Data" in the charts by checking arrays before rendering.
        } catch (error) {
          console.error('Error fetching chart data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchData();
  }, [timeRange, selectedDevice, selectedDate]);
  

  // Load Insights when insights date range changes
  useEffect(() => {
    const loadInsights = async () => {
      if (insightsStartDate && insightsEndDate) {
        setIsInsightsLoading(true);
        try {
          const insightsData = await fetchInsights(insightsStartDate, insightsEndDate);
          if (insightsData) {
            setInsights(insightsData);
          }
        } catch (error) {
          console.error('Error fetching insights:', error);
          setInsights(null);
        } finally {
          setIsInsightsLoading(false);
        }
      }
    };
    
    loadInsights();
  }, [insightsStartDate, insightsEndDate]);

  const handleInsightsDateChange = async (type, date) => {
    // Update the appropriate date
    if (type === 'start') {
      setInsightsStartDate(date);
    } else {
      setInsightsEndDate(date);
    }
  
    // If we have both dates, fetch new insights
    const newStartDate = type === 'start' ? date : insightsStartDate;
    const newEndDate = type === 'end' ? date : insightsEndDate;
  
    if (newStartDate && newEndDate) {
      setIsInsightsLoading(true);
      try {
        const newInsights = await fetchInsights(
          newStartDate,
          newEndDate,
          selectedDevice
        );
        if (newInsights) {
          setInsights(newInsights);
        }
      } catch (error) {
        console.error('Error fetching insights:', error);
      } finally {
        setIsInsightsLoading(false);
      }
    }
  };

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  
    // Just round selectedDate to the start of the chosen range.
    let newDate = new Date(selectedDate);
  
    if (range === 'hour') {
      newDate.setMinutes(0, 0, 0);
    } else if (range === 'day') {
      newDate.setHours(0, 0, 0, 0);
    } else if (range === 'month') {
      newDate.setDate(1);
      newDate.setHours(0, 0, 0, 0);
    }
  
    setSelectedDate(newDate.toISOString());
  };  

  // Helper function to check if a date would have valid data for the given range
const hasValidDataForRange = (date, range, metadataStart, metadataEnd) => {
  const rangeStart = new Date(date);
  const rangeEnd = new Date(date);
  
  switch(range) {
    case 'hour':
      rangeEnd.setHours(rangeEnd.getHours() + 1);
      break;
    case 'day':
      rangeEnd.setHours(23, 59, 59, 999);
      break;
    case 'month':
      rangeEnd.setMonth(rangeEnd.getMonth() + 1);
      rangeEnd.setDate(0);
      rangeEnd.setHours(23, 59, 59, 999);
      break;
  }
  
  return rangeStart >= metadataStart && rangeEnd <= metadataEnd;
};
  
  
  

const handleDeviceChange = async (deviceId) => {
  setSelectedDevice(deviceId);
  setIsLoading(true);
  try {
    const stats = await fetchCurrentStats(deviceId);
    setCurrentStats(stats);
    setLastUpdated(stats.timestamp || new Date().toISOString());
  } catch (error) {
    console.error('Error fetching stats for new device:', error);
  } finally {
    setIsLoading(false);
  }
};

  const handleDateChange = (date) => {
    const selected = new Date(date);
    const end = new Date(endDate);
  
    if (isNaN(selected.getTime())) {
      alert('Selected date is invalid.');
      return;
    }
  
    if (isNaN(end.getTime())) {
      alert('End date is invalid.');
      return;
    }
  
    if (selected <= end) {
      setSelectedDate(selected.toISOString());
    } else {
      alert('Selected date exceeds available data range.');
    }
  };
  
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
      setAppState('dashboard');
      // Here you might want to validate the token with your backend
    }
  }, [token]);

  const handleLogin = (newToken) => {
    setToken(newToken);
    setIsLoggedIn(true);
    setAppState('dashboard');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setIsLoggedIn(false);
    setAppState('login');
  };

// Handle Device Activation Complete
const handleActivationComplete = () => {
  setAppState('dashboard');
  // Here you might want to fetch initial data for the dashboard
};


const hasActivatedDevices = (devices) => {
  return devices.some(device => device.status === 'activated');
};


return (
  <div className="bg-primary-900 text-white min-h-screen">
    {appState === 'login' && (
      <Login 
        onLogin={handleLogin} 
        setAppState={setAppState}
      />
    )}
    
    {appState === 'forgot-password' && (
      <ForgotPassword 
        onBackToLogin={() => setAppState('login')} 
        setAppState={setAppState}
      />
    )}
    
    {appState === 'reset-password' && (
      <ResetPassword 
        token={resetToken}
        onResetComplete={() => setAppState('login')}
        setAppState={setAppState}
      />
    )}
    
    {appState === 'activate' && (
      <DeviceActivation onActivationComplete={handleActivationComplete} />
    )}
    
    {appState === 'initial-setup' && (
  initialSetupStep === 'power-up' ? (
    <InitialPowerView
      deviceName={devices.find(device => device.status === 'activated')?.name}
      onContinue={() => setInitialSetupStep('connecting')}
    />
  ) : (
    <InitialSetupView 
      deviceName={devices.find(device => device.status === 'activated')?.name}
      deviceId={devices.find(device => device.status === 'activated')?.device_id}
      onDataAvailable={() => {
        setAppState('dashboard');
        window.location.reload();
      }}
    />
  )
)}
    
    {appState === 'dashboard' && (
      <>
        {isLoadingDevices ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-accent-500"></div>
          </div>
        ) : !devices.length || !hasActivatedDevices(devices) ? (
          <AddDeviceView onDeviceAdded={handleDeviceAdded} />
        ) : (
          <div className="container mx-auto px-4 py-8">
            <Header 
              onSettingsClick={() => setShowSettingsModal(true)}
              onAlertsClick={() => setShowAlertsModal(true)}
              selectedDevice={selectedDevice}
              onDeviceChange={handleDeviceChange}
              lastUpdated={lastUpdated}
              onLogout={handleLogout}
              devices={devices.filter(device => device.status === 'activated')}
              onRefresh={handleRefresh}
              onUpdateRequired={refreshAllComponents}
            />
            <QuickStats stats={currentStats} />
            <ChartContainer
              timeRange={timeRange}
              onTimeRangeChange={handleTimeRangeChange}
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              selectedDevice={selectedDevice}
              forceUpdate={forceUpdate}
            />
            <Insights 
              startDate={insightsStartDate || startDate}
              endDate={insightsEndDate || endDate}
              onDateChange={handleInsightsDateChange}
              insights={insights}
              isLoading={isInsightsLoading}
            />
          </div>
        )}
        
        {showSettingsModal && (
          <SettingsModal 
            onClose={() => setShowSettingsModal(false)}
            selectedDevice={selectedDevice}
            onDeviceAdded={(newDevice) => {
              setDevices(prev => [...prev, newDevice]);
            }}
            onDeviceUpdated={(updatedDevices) => {
              setDevices(updatedDevices);
            }}
          />
        )}
{showAlertsModal && (
  <AlertsModal 
    onClose={() => setShowAlertsModal(false)}
    onUpdateAlerts={setAlerts}
    selectedDevice={selectedDevice}
  />
)}
      </>
    )}
  </div>
);
};

export default App;